import { makeAutoObservable } from "mobx";
import { ApiURL } from "../../../constants/URL";
import GlobalStore from "../../globalStore";
import { startOfMonth, endOfMonth } from 'date-fns';

class CustomerService {

  globalStore: GlobalStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeAutoObservable(this);
  }
  // Customers
  customers = [];

  async handleResponse(response) {
    if (response.ok) {
      const data = await response.json();
      if(data["hydra:member"] === undefined){
        return { success: true, data: data };
      }
      this.customers = data["hydra:member"];
      this.globalStore.isAuthenticated = true;
      return { success: true, data: this.customers };
    } else {
      return {
        success: false,
        error: `Error with status: ${response.status}`,
        status: response.status,
      };
    }
  }

  async getCustomers() {
    try {
      const response = await fetch(ApiURL.Customers, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async getCustomersById(id) {
    try {
      const response = await fetch(`${ApiURL.Customers}/${id}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async postCustomer(customerData) {
    try {
      const response = await fetch(ApiURL.Customers, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customerData),
      });

      return await this.handleResponse(response);
    } catch (error) {
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async putCustomer(id, customerData) {

    console.log("customerData", customerData);  
    try {
      const response = await fetch(`${ApiURL.Customers}/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(customerData),
      });

      return await this.handleResponse(response);
    } catch (error) {
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async deleteCustomer(id) {
    try {
        console.log("Tentative de suppression du client avec l'ID :", id);
        const response = await fetch(`${ApiURL.Customers}/${id}`, {
            method: "DELETE",
            headers: {
                Authorization: `Bearer ${this.globalStore.authService.token}`,
                "Content-Type": "application/json",
            },
        });

        if (response.status === 204) {
            return { success: true, data: null };
        }

        return await this.handleResponse(response);
    } catch (error) {
        console.error("Erreur lors de la suppression :", error); 
        return {
            success: false,
            error: `Network or parsing error: ${error.message}`,
        };
    }
  }
  async getCustomersPerMonth() {
    try {
        // Appel à l'API pour obtenir les clients
        const response = await fetch(ApiURL.Customers, {
            headers: {
                Authorization: `Bearer ${this.globalStore.authService.token}`,
                "Content-Type": "application/json",
            },
        });
        
        // Gère la réponse via handleResponse
        const apiResponse = await this.handleResponse(response);
        
        // Si la réponse n'est pas réussie, retourne l'erreur
        if (!apiResponse.success) {
            return apiResponse;
        }

        // Définir le début et la fin du mois courant
        const currentMonthStart = startOfMonth(new Date());
        const currentMonthEnd = endOfMonth(new Date());

        // Filtrer les clients créés ce mois-ci
        const customersThisMonth = apiResponse.data.filter(customer => {
            const createdAt = new Date(customer.createdAt); 
            return createdAt >= currentMonthStart && createdAt <= currentMonthEnd;
        });

        return { success: true, data: customersThisMonth };
    } catch (error) {
        // Gère toute erreur réseau ou autre exception
        return {
            success: false,
            error: `Network or parsing error: ${error.message}`,
        };
    }
}
}

export default CustomerService;

