import React from "react";
import { Document, Page, View, Text, Image } from "@react-pdf/renderer";
import logo_cevep from "../../../img/logo/logo_cevep.webp";

export function GeneratePdf(invoiceDataObject) {
  const invoiceData = invoiceDataObject.invoiceData;

  const taxRates = {};
  let totalAmount = 0;

  // Fonction pour formater les nombres en notation française
  function formatNumber(value) {
    return value
      .toFixed(2) // Toujours afficher 2 décimales
      .replace(".", ",") // Remplacer le point par une virgule
      .replace(/\B(?=(\d{3})+(?!\d))/g, " "); // Ajouter un espace pour les milliers
  }

  // Vérifier que les données de la facture sont disponibles et traiter les lignes
  if (invoiceData && invoiceData.invoiceLines) {
    invoiceData.invoiceLines.forEach((line) => {
      const amountHT = (line.price / 100) * line.quantity; // Conversion en euros
      const amountTVA = amountHT * (line.taxRate.amount / 100);
      const amountTTC = amountHT + amountTVA;
      totalAmount += amountTTC;

      // Stocker les informations de TVA pour chaque taux de taxe
      if (!taxRates[line.taxRate.amount]) {
        taxRates[line.taxRate.amount] = {
          value: line.taxRate.amount,
          totalAmountTVA: 0,
        };
      }
      taxRates[line.taxRate.amount].totalAmountTVA += amountTVA;
    });
  }

  if (!invoiceData) {
    return null; // Renvoie null si les données de la facture ne sont pas disponibles
  }

  const downPayment = invoiceData.payment?.DepositAmount || 0;
  const remainingAmount = totalAmount - downPayment;

  const styles = {
    page: {
      flexDirection: "column",
      padding: "12mm 15mm 18mm",
      fontSize: 10,
    },
    tableHeader: {
      flexDirection: "row",
      backgroundColor: "#f0f0f0",
      padding: 5,
    },
    tableCell: {
      flex: 1,
      borderRightWidth: 1,
      borderRightColor: "#f0f0f0", // Ajoute des bordures verticales
      textAlign: "center",
      padding: 10,
    },
    totals: {
      flexDirection: "row",
      justifyContent: "flex-end",
      width: "100%",
    },
    paymentTable: {
      marginTop: 20,
      borderWidth: 1,
      borderColor: "#ccc",
      width: "100%",
    },
    footer: {
      position: "absolute",
      bottom: 20, // Positionnement à 20 unités du bas
      left: 0, // Alignement à gauche
      right: 0, // Alignement à droite pour occuper toute la largeur
      width: "100%", // S'assurer que le footer occupe 100% de la largeur
      textAlign: "center", // Centre le texte
      fontSize: 8, // Taille adaptée pour le footer
      color: "#333", // Couleur du texte
      borderTopWidth: 1, // Ligne de séparation au-dessus du footer (facultatif)
      borderColor: "#ccc", // Couleur de la bordure
      paddingTop: 5, // Ajout d'espace entre la bordure et le texte
      margin: 0, // Supprime toutes les marges implicites
      padding: 0, // Supprime tout padding implicite
    },
    footerText: {
      fontSize: 10,
      fontWeight: "bold",
      color: "#33b540", // Couleur du texte
    },
    invoiceNumber: {
      fontWeight: "bold",
      fontSize: 20, // Augmenter la taille du numéro de facture
      textAlign: "center", // Centrer le texte
      marginTop: 10,
    },
  };

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={{ alignItems: "center" }}>
          <Image src={logo_cevep} style={{ width: 200 }} />
        </View>

        {/* En-tête de la facture */}
        <View style={{ marginTop: 50, flexDirection: "row" }}>
          <View style={{ width: "50%", alignItems: "center" }}>
            <Text style={{ fontWeight: "bold" }}>16 F rue des Jardins</Text>
            <Text style={{ fontWeight: "bold" }}>95480 PIERRELAYE</Text>
            <Text style={{ fontWeight: "bold" }}>Tél. : 01.34.32.53.53</Text>
            <Text style={{ fontSize: 8, color: "#33b540", fontWeight: "bold" }}>
              E. Mail. : pubccvep@gmail.com
            </Text>
            {/* Numéro de facture */}
            <Text style={styles.invoiceNumber}>
              Facture {invoiceData.number}
            </Text>
            <Text>{`Pierrelaye, le ${new Date(
              invoiceData.issuedAt
            ).toLocaleDateString("fr-FR")}`}</Text>
          </View>

          <View style={{ width: "50%", alignItems: "center" }}>
            {invoiceData.customer.companyName && (
              <Text>Client: {invoiceData.customer.companyName}</Text>
            )}
            {invoiceData.customer.numberSiret && (
              <Text>N°SIRET: {invoiceData.customer.numberSiret}</Text>
            )}
            {invoiceData.customer.numberTva && (
              <Text>N°TVA: {invoiceData.customer.numberTva}</Text>
            )}
          </View>
        </View>

        {/* Tableau des lignes de facture */}
        <View
          style={{
            width: "100%",
            marginTop: 20,
            borderWidth: 1,
            borderColor: "#ccc",
          }}
        >
          <View style={styles.tableHeader}>
            <View style={{ width: "55%", ...styles.tableCell }}>
              <Text style={{ fontWeight: "bold" }}>DESIGNATION</Text>
            </View>
            <View style={{ width: "10%", ...styles.tableCell }}>
              <Text style={{ fontWeight: "bold" }}>QTE</Text>
            </View>
            <View style={{ width: "20%", ...styles.tableCell }}>
              <Text style={{ fontWeight: "bold" }}>PRIX UNITAIRE EUROS</Text>
            </View>
            <View style={{ width: "15%", padding: 10 }}>
              <Text style={{ fontWeight: "bold" }}>MONTANT H.T EUROS</Text>
            </View>
          </View>

          {invoiceData.invoiceLines.map((line, index) => (
            <View
              key={index}
              style={{
                flexDirection: "row",
                borderTopWidth: 1,
                borderTopColor: "#ccc",
              }}
            >
              <View style={{ width: "55%", ...styles.tableCell }}>
                <Text>{line.description}</Text>
              </View>
              <View style={{ width: "10%", ...styles.tableCell }}>
                <Text>{line.quantity}</Text>
              </View>
              <View style={{ width: "20%", ...styles.tableCell }}>
                <Text>{formatNumber(line.price / 100)} €</Text>
              </View>
              <View style={{ width: "15%", padding: 10 }}>
                <Text>
                  {formatNumber((line.price / 100) * line.quantity)} €
                </Text>
              </View>
            </View>
          ))}
        </View>

        <View
          style={{
            marginTop: 20,
            fontSize: 10,
          }}
        >
          {/* En-tête des totaux */}
          <View
            style={{
              flexDirection: "row",
              backgroundColor: "#f0f0f0",
              padding: 5,
            }}
          >
            <Text style={{ flex: 2, fontWeight: "bold", textAlign: "left" }}>
              Description
            </Text>
            <Text style={{ flex: 1, fontWeight: "bold", textAlign: "right" }}>
              Montant
            </Text>
          </View>

          {/* Lignes dynamiques pour les taux de TVA */}
          {Object.entries(taxRates).map(([rate, values], index) => (
            <View
              key={index}
              style={{
                flexDirection: "row",
                borderBottomWidth: 1,
                borderColor: "#ccc",
                padding: 5,
              }}
            >
              <Text
                style={{ flex: 2, textAlign: "left" }}
              >{`Taux de TVA ${rate}%`}</Text>
              <Text style={{ flex: 1, textAlign: "right" }}>
                {formatNumber(values.totalAmountTVA)} €
              </Text>
            </View>
          ))}

          {/* Montant total */}
          <View
            style={{
              flexDirection: "row",
              borderBottomWidth: 1,
              borderColor: "#ccc",
              padding: 5,
            }}
          >
            <Text style={{ flex: 2, fontWeight: "bold", textAlign: "left" }}>
              Montant total
            </Text>
            <Text style={{ flex: 1, fontWeight: "bold", textAlign: "right" }}>
              {formatNumber(totalAmount)} €
            </Text>
          </View>
          <View>
            <Text>
              {" "}
              Conditions de réglement : A réception de la facture conditions
              d'escompte Intéréts de retard : 1,5 fois le taux d'intéret légal
              par mois Indemnité Forfaitaire de 40E. Pénalités applicable en cas
              de retard TVA N° FR51353688591 Acquittée sur les Encaissements
            </Text>
          </View>
          <View style={{ alignItems: "center", marginTop: "210px" }}>
            <Text style={styles.footerText}>
              Siège social: Pierrelaye (Val d'Oise) S.A.R.L. au Capital de
              8000€. RCS: 353 688 591 00067 Code APE: 7311Z
            </Text>
          </View>
        </View>
      </Page>
    </Document>
  );
}
