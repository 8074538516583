import React from "react";
import { Box, Button, colors, Typography } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { InvoiceData } from "./types";

interface InvoiceHeaderProps {
  invoiceData: InvoiceData;
  setFieldValue: (field: string, value: any) => void;
  handleClickSubmit: (status: string) => void;
}

const InvoiceHeader: React.FC<InvoiceHeaderProps> = ({
  invoiceData,
  setFieldValue,
  handleClickSubmit,
}) => {
  // Vérifier si la facture peut être annulée
  const canCancel = invoiceData.status === "PUBLISHED";

  return (
    <Box
      sx={{
        background: colors.grey[700],
        top: "-50px",
        minHeight: "50px",
        justifyContent: "space-between",
        display: "flex",
        padding: 2,
      }}
    >
      <Typography variant="h4">Détails de la facture</Typography>
      {invoiceData.status === "DRAFT" ? (
        <Button
          href={`/invoice/edit/${invoiceData.id}`}
          variant="contained"
          color="secondary"
        >
          <SyncAltIcon /> Modifier
        </Button>
      ) : canCancel ? (
        <Button
        onClick={() => {
          // Met à jour le statut à "CANCELLED"
          setFieldValue("statut", "CANCELLED");
          handleClickSubmit("CANCELLED"); // Passez "CANCELLED" ici
        }}
          variant="contained"
          color="error"
        >
          Annuler la facture
        </Button>
      ) : null}
    </Box>
  );
};

export default InvoiceHeader;
