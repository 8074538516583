import React, { useEffect, useState, useRef } from "react";
import html2pdf from "html2pdf.js";
import { ThemeModal } from "../../../components/modal/Modal";
import { Box, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import { CreateViewProduct } from "./createViewProduct.jsx";

export function PreviewProductModal({ open, handleClose, selectedProduct }) {
  const theme = useTheme();
  const colors = theme.palette ? tokens(theme.palette.mode) : null;
  const store = useGlobalStore();
  const [productData, setProductData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    const fetchProducts = async () => {
      setIsLoading(true);
      try {
        const result = await store.product.getProducts();
        if (result.success) {
          const selectedProductData = result.data.find(
            (p) => p.id === selectedProduct.id
          );
          setProductData(selectedProductData);
        } else {
          console.error(result.error);
        }
      } catch (error) {
        console.error("Erreur lors de la récupération des données :", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (open && selectedProduct !== null && selectedProduct !== undefined) {
      fetchProducts();
    }
  }, [open, selectedProduct, store.product]);

  const generatePDF = () => {
    if (contentRef.current) {
      html2pdf()
        .from(contentRef.current)
        .set({
          margin: 0,
          filename: `Produit_${productData?.name || "sans_nom"}.pdf`,
          html2canvas: { scale: 2, useCORS: true },
          jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
        })
        .save();
    }
  };

  return (
    <ThemeModal open={open} onClose={handleClose}>
      <Box className="modal-content">
        <Box
          sx={{
            background: colors.grey[400],
            display: "flex",
            justifyContent: "flex-start",
            padding: "16px",
          }}
          className="modal-header"
        >
          <Button
            onClick={generatePDF}
            style={{ color: colors.grey[100] }}
            className="modal-close"
            disabled={isLoading}
          >
            Télécharger PDF
          </Button>
        </Box>
        <Box className="modal-scroll">
          <Box ref={contentRef}>
            <CreateViewProduct productData={productData} />
          </Box>
        </Box>
      </Box>
    </ThemeModal>
  );
}
