import React from "react";
import { Grid, Paper, Typography, Divider, Box } from "@mui/material";
import { InvoiceData } from "./types";

interface InvoiceDetailsProps {
  invoiceData: InvoiceData;
  InvoiceDetailsGridSize: number;
  StatusComponent: React.ReactNode;
}

const InvoiceDetails: React.FC<InvoiceDetailsProps> = ({
  invoiceData,
  InvoiceDetailsGridSize,
  StatusComponent,
}) => (
  <Grid item xs={InvoiceDetailsGridSize} sx={{ padding: 2 }}>
    <Paper elevation={3} sx={{ padding: 4 }}>
      <Typography variant="h5" gutterBottom>
        Détails de la facture
      </Typography>
      <Divider sx={{ marginBottom: 2 }} />
      <Typography variant="subtitle1">
        <strong>Numéro de facture:</strong> {invoiceData.number}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Créé le:</strong> {invoiceData.formatedIssuedAt}
      </Typography>
      <Typography variant="subtitle1">
        <strong>Échéance:</strong> {invoiceData.formatedDueDate}
      </Typography>
      {/* Intégration du composant StatusComponent */}
 
        <Box mt={4}>
          <Typography variant="subtitle1">Modifier le statut</Typography>
          {StatusComponent}
        </Box>
      
    </Paper>
  </Grid>
);

export default InvoiceDetails;
