
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
} from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../theme";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { Table } from "../../components/table/Table";
import { ToastContainer } from "react-toastify";
import Dashboard from "../dashboard/DashboardIndex.tsx";
import { Link } from "react-router-dom";

const User = () => {
  const store = useGlobalStore();
  const isAuth = store.authService.isAuth();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const [isLoading, setIsLoading] = useState(false);
  const [usersData, setUsersData] = useState([]);

  const fetchUsers = async () => {
    setIsLoading(true);
    try {
      const result = await store.userService.getUsers();
      if (result.success) {
        setUsersData(
          result.data.map((userProxy) => {
            const user = userProxy;
            const roles = user.roles[0];
            return {
              id: user.id,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              phone: user.phone,
              permission: roles,
              created_at: user.created_at,
              updated_at: user.updated_at,
            };
          })
        );
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [store.users]);

  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "firstName",
      headerName: "Prénom",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "lastName",
      headerName: "Nom",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "phone",
      headerName: "Numéro de téléphone",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "permission",
      headerName: "Niveau d'accès",
      flex: 1,
      renderCell: ({ row: { permission } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="LEFT"
            backgroundColor={
              permission === "ROLE_ADMIN"
                ? colors.greenAccent[600]
                : permission === "ROLE_MANAGER"
                ? colors.greenAccent[700]
                : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {permission === "ROLE_ADMIN" && <AdminPanelSettingsOutlinedIcon />}
            {permission === "ROLE_MANAGER" && <SecurityOutlinedIcon />}
            {permission === "ROLE_USER" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {permission === "ROLE_ADMIN" && "Admin"}
              {permission === "ROLE_MANAGER" && "Manager"}
              {permission === "ROLE_USER" && "Utilisateur"}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "EDITER",
      type: "string",
      flex: 1,
      headerAlign: "left",
      renderCell: ({ row: { id } }) => (
        <strong>
          <Link to={`/user/edit/${id}`}>
            <Button
              variant="contained"
              size="small"
              borderRadius="5px"
              style={{
                backgroundColor: colors.grey[700],
                color: colors.grey[100],
              }}
            >
              Ouvrir
            </Button>
          </Link>
        </strong>
      ),
    },
  ];

  return (
    <Box m="20px">
      <Dashboard
        title={"Utilisateurs"}
        subtitle={
          "Retrouvez toutes les données de votre équipe ainsi que la gestion de leurs droits d'accès."
        }
        element={
          <Button href="/user/add" variant="contained" color="secondary">
            <AddIcon />
            Ajouter
          </Button>
        }
      />

      {isLoading ? (
        <CircularProgress />
      ) : (
        <Table Data={usersData} columns={columns} />
      )}
      <ToastContainer />
    </Box>
  );
};

export default User;
