export function formatDate(date, inputFormat = false) {
  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear();
  const month = String(formattedDate.getMonth() + 1).padStart(2, "0");
  const day = String(formattedDate.getDate()).padStart(2, "0");

  if (inputFormat) {
    return `${year}-${month}-${day}`;
  } else {
    return `${day}-${month}-${year}`;
  }
}

export function formatInputDate(billingDate) {
  return formatDate(billingDate, true);
}

export function formatUserDate(billingDate) {
  return formatDate(billingDate, false);
}
