import { makeAutoObservable } from "mobx";
import * as jsrsasign from "jsrsasign";
import { ApiURL } from "../../constants/URL";
import GlobalStore from "../globalStore";

interface user {
  email: string | null;
  permission: string | null;
  id : string | null;
}

interface JWTResponse {
  token: string;
}

class AuthService {

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeAutoObservable(this);
    this.checkTokenValidity();
  }

  user: user = {
    email: null,
    permission: null,
    id: null,
  };

  globalStore: GlobalStore;

  token: string | null = localStorage.getItem("token") || null;

  setUser(data: user) {
    this.user.email = data.email;
    this.user.permission = data.permission;
    this.user.id = data.id;
  }

  setToken(token: string | null) {
    this.token = token;
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token");
    }
  }

  isAuth(): boolean {
    return this.token !== null && !this.isTokenExpired(this.token);
  }

  getRoles(): string | null {
    return this.user.permission;
  }

  connexion = async (email: string, password: string): Promise<boolean> => {
    const login = {
      email: email,
      password: password,
    };

    try {
      const response = await fetch(ApiURL.jwtLogin, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(login),
        mode: "cors",
      });

      if (response.ok) {
        const data: JWTResponse = await response.json();
        const jwt = data.token;
        const decodedToken = jsrsasign.jws.JWS.parse(jwt);
        const payload = decodedToken.payloadObj;
        this.setUser(payload.user_data);
        this.setToken(jwt);
        window.location.href = '/';
        return true;
      } else {
        console.error("Error with status:", response.status);
        return false;
      }
    } catch (error) {
      console.error("Network error:", error);
      return false;
    }
  };

  logout = (): void => {
    this.setUser({ email: null, permission: null, id: null });
    this.setToken(null);
    window.location.href = '/login';
  };

  isTokenExpired = (token: string): boolean => {
    try {
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;
      return decodedToken.exp < currentTime;
    } catch (e) {
      return true;
    }
  };

  checkTokenValidity = (): void => {
    if (this.token && this.isTokenExpired(this.token)) {
      this.logout();
    }
  };
}

export default AuthService;
