import { makeAutoObservable } from "mobx";
import { ApiURL } from "../../../constants/URL";
import GlobalStore from "../../globalStore";

// Define the InvoiceResponse type
interface InvoiceResponse {
  success: boolean;
  data?: any;
  error?: string;
  status?: number;
}

interface Invoice {}

class InvoiceService {
  globalStore: GlobalStore;
  invoices: Invoice[] = [];

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeAutoObservable(this);
  }

  // Méthode pour gérer les réponses
  async handleResponse(response) {
    if (response.ok) {
      try {
        const data = await response.json();
        this.globalStore.isAuthenticated = true;
        if (data && data["hydra:member"]) {
          this.invoices = data["hydra:member"];
          return { success: true, data: this.invoices };
        }
        return { success: true, data };
      } catch (error) {
        return {
          success: false,
          error: `Failed to parse JSON: ${error.message}`,
          status: response.status,
        };
      }
    } else {
      return {
        success: false,
        error: `Error with status: ${response.status}`,
        status: response.status,
      };
    }
  }
  

  // Méthode pour générer un numéro de facture
  async generateInvoiceNumber(format, prefix) {
    try {
      const response = await fetch(
        `${ApiURL.Invoices}/number/generate?format=${format}&prefix=${prefix}`,
        {
          headers: {
            Authorization: `Bearer ${this.globalStore.authService.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Fetch all invoices
  async getInvoices(): Promise<InvoiceResponse> {
    try {
      const response = await fetch(ApiURL.Invoices, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Fetch an invoice by its ID
  async getInvoiceById(id: number): Promise<InvoiceResponse> {
    try {
      const response = await fetch(`${ApiURL.InvoicesById}${id}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Post a new invoice
  async postInvoice(
    invoiceData: Record<string, any>
  ): Promise<InvoiceResponse> {
    try {
      const response = await fetch(ApiURL.Invoices, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(invoiceData),
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Update an existing invoice
  async putInvoice(
    id: string,
    invoiceData: Record<string, any>
  ): Promise<InvoiceResponse> {
    try {
      const response = await fetch(`${ApiURL.Invoices}/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(invoiceData),
      });
      console.log("invoiceDatadandata", invoiceData);
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }
  // Update invoice status
  async updateInvoiceStatus(
    id: number,
    status: string,
    installmentSchedule: Array<string | null>
  ): Promise<InvoiceResponse> {
    try {
      const response = await fetch(`${ApiURL.Invoices}/${id}/updatestatus`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ status, installmentSchedule }),
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Fetch invoices by a specific user ID
  async getInvoicesByUserId(id: string): Promise<InvoiceResponse> {
    try {
      const response = await fetch(`${ApiURL.InvoicesByUserId}/${id}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Fetch tax rates
  async getTaxeRates(): Promise<InvoiceResponse> {
    try {
      const response = await fetch(ApiURL.taxeRates, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Nouvelle méthode : Marquer une facture comme "Non Payé"
  async markInvoiceAsNonPaid(id: number): Promise<InvoiceResponse> {
    try {
      const response = await fetch(
        `${ApiURL.Invoices}/${id}/mark_as_non_paid`,
        {
          method: "PUT",
          headers: {
            Authorization: `Bearer ${this.globalStore.authService.token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  // Méthode pour récupérer les paiements
  async getPayments(): Promise<InvoiceResponse> {
    try {
      const response = await fetch(ApiURL.Invoices, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });

      // Vérifiez si la réponse est correcte
      if (!response.ok) {
        throw new Error(`Erreur HTTP : ${response.status}`);
      }

      // Appelez handleResponse directement sur la réponse
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }
}

export default InvoiceService;
