import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Select,
  MenuItem,
} from "@mui/material";
import { Menu } from "react-pro-sidebar";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";

const EditInvoiceSidebar = ({ onSubmit }) => {
  const store = useGlobalStore();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [invoiceType, setInvoiceType] = useState("Simple"); // "Simple" est l'option par défaut
  const [frequency, setFrequency] = useState("Mensuelle");
  const [duration, setDuration] = useState(5);
  const [sendMethod, setSendMethod] = useState("Créer seulement");
  const [durationType, setDurationType] = useState("Limité");
  const [depositAmount, setDepositAmount] = useState(0); // Pour l'acompte
  const [installments, setInstallments] = useState(1); // Nombre de paiements en plusieurs fois
  const [hasDeposit, setHasDeposit] = useState(false);
  const [hasInstallments, setHasInstallments] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("virement");

  // Fonction pour valider les données
  const validateData = () => {
    if (invoiceType === "Simple") {
      // Vérifier que le montant de l'acompte est positif si un acompte est demandé
      if (hasDeposit && (isNaN(depositAmount) || depositAmount <= 0)) {
        alert("Veuillez entrer un montant d'acompte valide.");
        return false;
      }
      // Vérifier que le nombre d'installments est positif si le paiement en plusieurs fois est demandé
      if (
        hasInstallments &&
        (isNaN(Number(installments)) || Number(installments) < 1)
      ) {
        alert("Veuillez entrer un nombre de paiements valide.");
        return false;
      }
    }
    // Si tout est valide
    return true;
  };

  const handleSubmit = () => {
    if (!validateData()) return; // If the data is not valid, do not submit

    let invoiceData;

    if (invoiceType === "Simple") {
      invoiceData = {
        send_method: sendMethod,
        payment_method: paymentMethod,
        discount_amount: hasDeposit ? depositAmount : null,
        installments: hasInstallments ? installments : 1,
        invoice_type: "simple",
      };
    } else {
      // Récurrente
      invoiceData = {
        send_method: sendMethod,
        payment_method: paymentMethod,
        frequency: frequency || null,
        duration:
          durationType === "Illimité"
            ? null
            : durationType === "Limité"
            ? duration
            : null,
        is_infinite: durationType === "Illimité",
        invoice_type: "recurring",
      };
    }
    onSubmit(invoiceData);
  };

  return (
    <Box
      sx={{
        width: 250,
        bgcolor: colors.grey[800],
        padding: 2,
        position: "fixed",
        right: 0,
        top: 0,
        height: "100vh",
        overflowY: "auto",
        "& .pro-sidebar-inner": {
          background: `${colors.grey[100]} !important`,
        },
      }}
    >
      <Typography variant="h6" gutterBottom>
        Paramètres de Facture
      </Typography>
      <Divider sx={{ marginBottom: 2, bgcolor: colors.grey[600] }} />
      <Menu>
        <MenuItem
          onClick={() => setInvoiceType("Simple")}
          style={{
            backgroundColor:
              invoiceType === "Simple" ? colors.primary[500] : "transparent",
            color: invoiceType === "Simple" ? "#fff" : colors.grey[200],
          }}
        >
          Facture Simple
        </MenuItem>
        <MenuItem
          onClick={() => setInvoiceType("Récurrente")}
          style={{
            backgroundColor:
              invoiceType === "Récurrente"
                ? colors.primary[500]
                : "transparent",
            color: invoiceType === "Récurrente" ? "#fff" : colors.grey[200],
          }}
        >
          Facture Récurrente
        </MenuItem>
      </Menu>
      <Divider sx={{ margin: "20px 0", bgcolor: colors.grey[600] }} />
      {invoiceType === "Récurrente" && (
        <>
          <Box mb={2}>
            <Typography variant="subtitle1">Méthode de paiement</Typography>
            <Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              fullWidth
              sx={{ marginBottom: 1 }}
            >
              <MenuItem value="virement">Virement</MenuItem>
              <MenuItem value="cheque">Chèque</MenuItem>
              <MenuItem value="prelevement.bancaire">
                Prélèvement bancaire
              </MenuItem>
              <MenuItem value="carte.bancaire">Carte bancaire</MenuItem>
            </Select>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle1">Fréquence</Typography>
            <Select
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}
              fullWidth
              sx={{ marginBottom: 1 }}
            >
              <MenuItem value="semaine">Hebdomadaire</MenuItem>
              <MenuItem value="mois">Mensuelle</MenuItem>
              <MenuItem value="bimensuel">Bimestrielle</MenuItem>
              <MenuItem value="trimestriel">Trimestrielle</MenuItem>
              <MenuItem value="semestre">Semestrielle</MenuItem>
              <MenuItem value="annuel">Annuelle</MenuItem>
              <MenuItem value="bi-anuel">Bi-annuelle</MenuItem>
            </Select>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle1">Type de Durée</Typography>
            <Select
              value={durationType}
              onChange={(e) => setDurationType(e.target.value)}
              fullWidth
              sx={{ marginBottom: 1 }}
            >
              <MenuItem value="Limité">Durée Limité</MenuItem>
              <MenuItem value="Illimité">Durée Illimitée</MenuItem>
            </Select>
          </Box>
          {durationType === "Limité" && (
            <Box mb={2}>
              <Typography variant="subtitle1">Durée</Typography>
              <TextField
                type="number"
                value={duration}
                onChange={(e) => {
                  const value = Math.max(0, e.target.value);
                  setDuration(value);
                }}
                fullWidth
                inputProps={{
                  min: 0,
                }}
                sx={{ marginBottom: 1 }}
              />
            </Box>
          )}
        </>
      )}
      {invoiceType === "Simple" && (
        <>
          <Box mb={2}>
            <Typography variant="subtitle1">Méthode de paiement</Typography>
            <Select
              value={paymentMethod}
              onChange={(e) => setPaymentMethod(e.target.value)}
              fullWidth
              sx={{ marginBottom: 1 }}
            >
              <MenuItem value="virement">Virement</MenuItem>
              <MenuItem value="cheque">Chèque</MenuItem>
              <MenuItem value="prelevement.bancaire">
                Prélèvement bancaire
              </MenuItem>
              <MenuItem value="carte.bancaire">Carte bancaire</MenuItem>
            </Select>
          </Box>
          <Box mb={2}>
            <Typography variant="subtitle1">Y a-t-il un acompte ?</Typography>
            <Select
              value={hasDeposit ? "Oui" : "Non"}
              onChange={(e) => setHasDeposit(e.target.value === "Oui")}
              fullWidth
              sx={{ marginBottom: 1 }}
            >
              <MenuItem value="Non">Non</MenuItem>
              <MenuItem value="Oui">Oui</MenuItem>
            </Select>
          </Box>

          {hasDeposit && (
            <Box mb={2}>
              <Typography variant="subtitle1">Montant de l'acompte</Typography>
              <TextField
                type="number"
                value={depositAmount}
                onChange={(e) =>
                  setDepositAmount(parseFloat(e.target.value) || 0)
                } // Conversion à un nombre
                placeholder="Montant de l'acompte"
                fullWidth
                sx={{
                  marginBottom: 1,
                  bgcolor: colors.grey[700],
                  color: colors.grey[100],
                }}
              />
            </Box>
          )}

          <Box mb={2}>
            <Typography variant="subtitle1">
              Paiement en plusieurs fois ?
            </Typography>
            <Select
              value={hasInstallments ? "Oui" : "Non"}
              onChange={(e) => setHasInstallments(e.target.value === "Oui")}
              fullWidth
              sx={{ marginBottom: 1 }}
            >
              <MenuItem value="Non">Non</MenuItem>
              <MenuItem value="Oui">Oui</MenuItem>
            </Select>
          </Box>

          {hasInstallments && (
            <Box mb={2}>
              <Typography variant="subtitle1">Nombre de paiements</Typography>
              <TextField
                type="number"
                value={installments}
                onChange={(e) => {
                  const value = Math.max(1, e.target.value); // Empêche les valeurs négatives ou zéro
                  setInstallments(value);
                }}
                placeholder="Nombre de paiements"
                fullWidth
                sx={{
                  marginBottom: 1,
                  bgcolor: colors.grey[700],
                  color: colors.grey[100],
                }}
              />
            </Box>
          )}
        </>
      )}

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit}
        sx={{
          bgcolor: colors.primary[500],
          color: "#fff",
          "&:hover": {
            bgcolor: colors.primary[600],
          },
        }}
      >
        Enregistrer les paramètres
      </Button>
    </Box>
  );
};

export default EditInvoiceSidebar;
