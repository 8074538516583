import * as Yup from "yup";

export interface Customer {
    id: number;
    companyName: string;
    address: string;
    // Ajoutez d'autres propriétés si nécessaire
  }
  
  export interface InvoiceLine {
    description: string;
    quantity: number;
    price: number;
    position: number;
    taxRateId: number;
    taxRateValue: number;
    product: string;
  }
  
  export interface Invoice {
    id: number;
    number: string;
    issuedAt: string; // Date sous forme de chaîne ISO
    message: string;
    paymentDaysCondition: number;
    invoiceLines: InvoiceLine[];
    customer: Customer;
    totalWithTax: string; // Montant total avec taxes sous forme de chaîne
    // Ajoutez d'autres propriétés si nécessaire
  }
  
  
  export const checkoutSchema = Yup.object().shape({
    selectedCustomer: Yup.number().required("Le client est requis").nullable(),
    number: Yup.string().required("Le numéro de facture est requis"),
    issuedAt: Yup.string().required("La date de facturation est requise"),
    paymentDaysCondition: Yup.number().required("Les conditions de paiement sont requises"),
  });
  