import "./login.css";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { Box, Button, Grid, TextField, useTheme } from "@mui/material";
import { useGlobalStore } from "../../store/globalStore.tsx";
import chien from "../../img/login/chien.webp";
import { tokens } from "../../theme";
import { Navigate, useNavigate } from "react-router-dom";

const Login = () => {
  const store = useGlobalStore();
  const navigate = useNavigate();
  console.log(store.token);
  {
    if (store.token) {
      navigate("/dashboard"); 
    }
  }

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const onChangeEmail = ({ currentTarget }) => {
    setEmail(currentTarget.value);
  };

  const onChangePassword = ({ currentTarget }) => {
    setPassword(currentTarget.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    let isConnect = store.authService.connexion(email, password);
    isConnect.then((res) => {
      if (res === true) {
        navigate("/s");
      } else {
        alert("Email ou mot de passe incorrect");
      }
    });
  };

  return (
    <Box>
      <Grid className="login" container>
        <Grid item xs={false} sm={6}>
          <Box className="img-container">
            <img src={chien} className="login-img" />
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Box
            sx={{ background: colors.transparent[400] }}
            className="form-login"
          >
            <Box className="input-container">
              <TextField
                id="outlined-basic"
                label="Email"
                variant="outlined"
                value={email}
                onChange={onChangeEmail}
              />
            </Box>
            <Box className="input-container">
              <TextField
                type="password"
                id="outlined-basic"
                label="Password"
                variant="outlined"
                value={password}
                onChange={onChangePassword}
              />
            </Box>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              className="input-container"
            >
              <Button variant="contained" onClick={onSubmit} type="submit">
                connexion
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default observer(Login);
