import React from "react";
import { Box } from "@mui/material";
import Header from "../../components/header/Header";


interface DashboardProps {
  title?: string;
  subtitle?: string;
  element?: React.ReactNode;
}

const Dashboard: React.FC<DashboardProps> = ({ title, subtitle, element }) => {
  return (
    <Box m="20px">
      <Box
        textAlign="left"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Header title={title} subtitle={subtitle} />
        <Box>{element}</Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
