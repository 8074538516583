import { useEffect, useState } from "react";
import { Box, Button, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import { textFieldStyleClassic } from "../../../components/style/componentStyle";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { toast } from "react-toastify";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import { ReturnStringFromTable } from "../../../utils/arrayUtils";
import {
  useParams,
  Navigate,
  useLocation,
  useNavigate,
} from "react-router-dom";
import Dashboard from "../../dashboard/DashboardIndex.tsx";
import AddIcon from "@mui/icons-material/Add";

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
const postalCodeRegExp = /^\d{5}$/;
const siretRegExp = /^\d{14}$/;

const userSchema = Yup.object({
  first_name: Yup.string("Prénom requis"),
  last_name: Yup.string("Nom requis"),
  email: Yup.string().email("Email invalide"),
  phone: Yup.string().matches(phoneRegExp, "Numéro de téléphone invalide"),
  password: Yup.string()
    .min(8, "Le mot de passe doit contenir au moins 8 caractères")
    .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/, "Le mot de passe doit contenir au moins une minuscule, une majuscule, un chiffre et un caractère spécial"),
});

const EditUser = () => {
  const store = useGlobalStore();
  const { id } = useParams();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [mode, setMode] = useState("edit");
  const [userData, setUserData] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();
  const location = useLocation();

  const href = `/user/edit/${id}`;

  /* ------------------- useEffect ------------------- */

  // Effets
  useEffect(() => {
    console.log("Navigate");
    if (!id && location.pathname !== "/user/add") {
      navigate("/user/add");
    }
  }, [id, location, navigate]);

  useEffect(() => {
    if (!id) {
      setIsLoading(false);
      setMode("add");
      return;
    }
    fetchUser();
  }, [id, store.userService]);

  {
    /* ------------------- Fonction ------------------- */
  }

  const fetchUser = async () => {
    // Logique de récupération des données utilisateur
    try {
      const result = await store.userService.getUserById(id);
      console.log(result, "result");
      if (result.success) {
        setUserData(result.data);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const userData = buildUserData(values);

    console.log("Données utilisateur à soumettre:", userData); // Log des données utilisateur

    if (values.password !== "" && values.password !== values.confirmPassword) {
      toast.error("Les mots de passe ne correspondent pas.");
      return;
    }

    const { confirmPassword, ...userDataToSend } = userData;

    try {
      let result = null;
      if (mode === "add") {
        result = await store.userService.postUser(userDataToSend);
        if (result.success) {
          toast.success("Utilisateur enregistré avec succès.");
          navigate("/user");
        }
      } else if (mode === "edit") {
        result = await store.userService.putUser(id, userDataToSend);
        if (result.success) {
          toast.success("Utilisateur modifié avec succès.");
        }
      }
      if (result.message) {
        toast.error(result.message);
      } else if (result.error) {
        toast.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);
    } finally {
      setSubmitting(false);
    }
  };

  const buildUserData = (values) => {
    const isPasswordEmpty = values.password === "";

    const userData = {
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      phone: values.phone,
      roles: [values.roles],
    };

    if (!isPasswordEmpty) {
      userData.password = values.password;
    }

    return userData;
  };

  const initialValues = {
    firstName: userData.firstName || "",
    lastName: userData.lastName || "",
    email: userData.email || "",
    phone: userData.phone || "",
    password: "",
    confirmPassword: "",
    roles: ReturnStringFromTable(userData.roles) || "ROLE_USER",
  };

  return (
    <>
      <Box m="20px">
        <Dashboard
          title={"Utilisateur"}
          subtitle={"Modifier un utilisateur"}
          element={
            <Button href="/user" variant="contained" color="secondary">
              <AddIcon />
              retour
            </Button>
          }
        />
        {isLoading ? (
          <div>Chargement...</div>
        ) : (
          <Formik
            onSubmit={handleSubmit}
            initialValues={initialValues}
            validationSchema={userSchema}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleChange,
              handleSubmit,
              dirty,
            }) => (
              <form id="FormUser" onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <InputLabel id="demo-simple-select-label">Rôle de l'utilisateur</InputLabel> 
                    <Select
                      fullWidth
                      label="Rôle"
                      id="demo-simple-select"
                      value={values.roles} // Utilisez simplement values.roles sans le tableau
                      name="roles"
                      onChange={handleChange}
                    >
                      <MenuItem value="ROLE_USER">Utilisateur</MenuItem>
                      <MenuItem value="ROLE_MANAGER">Manager</MenuItem>
                      <MenuItem value="ROLE_ADMIN">Administrateur</MenuItem>
                    </Select>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={textFieldStyleClassic}
                      fullWidth
                      variant="filled"
                      label="Prénom"
                      name="firstName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.firstName}
                      error={touched.firstName && !!errors.firstName}
                      helpertext={touched.firstName && errors.firstName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={textFieldStyleClassic}
                      fullWidth
                      variant="filled"
                      label="Nom de famille"
                      name="lastName"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.lastName}
                      error={touched.lastName && !!errors.lastName}
                      helpertext={touched.lastName && errors.lastName}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={textFieldStyleClassic}
                      fullWidth
                      variant="filled"
                      label={errors.email ? errors.email : "email"}
                      name="email"
                      type="email"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.email}
                      error={touched.email && !!errors.email}
                      helpertext={touched.email && errors.email}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={textFieldStyleClassic}
                      fullWidth
                      variant="filled"
                      label={
                        errors.phone ? errors.phone : "Numéro de téléphone"
                      }
                      name="phone"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.phone}
                      error={touched.phone && !!errors.phone}
                      helpertext={touched.phone && errors.phone}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={textFieldStyleClassic}
                      fullWidth
                      variant="filled"
                      label={errors.password ? errors.password : "Mot de passe"}
                      name="password"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.password}
                      error={errors.password}
                      helpertext={touched.password && errors.password}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      sx={textFieldStyleClassic}
                      fullWidth
                      variant="filled"
                      label="Confirmation du mot de passe"
                      name="confirmPassword"
                      type="password"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={values.confirmPassword}
                      error={
                        touched.confirmPassword && !!errors.confirmPassword
                      }
                      helperText={
                        touched.confirmPassword && errors.confirmPassword
                      }
                    />
                  </Grid>
                </Grid>
                {dirty && (
                  <Box>
                    <Button
                      sx={{ mt: "20px" }}
                      fullWidth
                      form="FormUser"
                      variant="contained"
                      color="secondary"
                      type="submit"
                    >
                      Enregistrer
                    </Button>
                  </Box>
                )}
              </form>
            )}
          </Formik>
        )}
      </Box>
    </>
  );
};

export default EditUser;
