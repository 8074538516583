import { Box, useTheme } from "@mui/material";
import { DataGrid, frFR } from "@mui/x-data-grid";
import { tokens } from "../../theme";



export function Table ({ Data, columns })  {
    
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return <Box
    m="40px 0 0 0"
    maxHeight="75vh"
    sx={{
      "& .MuiDataGrid-root": {
        maxHeight: "70vh",
      },
      "& .MuiDataGrid-cell": {
        borderBottom: "none",
      },
      "& .name-column--cell": {
        color: colors.greenAccent[300],
      },
      "& .MuiDataGrid-columnHeader": {
        backgroundColor: colors.blueAccent[700],
        borderBottom: "none",
      },
      "& .MuiDataGrid-virtualScroller": {
        backgroundColor: colors.primary[400],
      },
      "& .MuiDataGrid-footerContainer": {
        borderTop: "none",
        backgroundColor: colors.blueAccent[700],
      },
    }}>

    <DataGrid localeText={frFR.components.MuiDataGrid.defaultProps.localeText} rows={Data} columns={columns} />

  </Box>;
};

