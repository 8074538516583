import React from "react";
import { Grid, Paper, Typography, Box, TextField, Button } from "@mui/material";
import { InstallmentSchedule } from "./types";

interface PaymentDetailsProps {
  Values: any;
  handleChange: (event: React.ChangeEvent<any>) => void;
  handleBlur: (event: React.FocusEvent<any>) => void;
}

const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  Values,
  handleChange,
  handleBlur,
}) => {
  // Log des valeurs à chaque rendu
  console.log("À l'intérieur du composant:", Values);

  return (
    <Grid item xs={6} sx={{ padding: 2 }}>
      <Paper elevation={3} sx={{ padding: 4, minWidth: "400px" }}>
        <Typography variant="h5" gutterBottom>
          Détails des Paiements
        </Typography>
        <Box>
          {/* Boucle sur les paiements et leurs échéances */}
          {Values.installmentSchedule.map((payment, index: number) => {
            // Récupérer le plan d'échéance correspondant
            const schedule: InstallmentSchedule =
              Values.payment?.installmentSchedules[index];

            return (
              <Grid container key={index} spacing={2} sx={{ marginBottom: 2 }}>
                <Grid item xs={6}>
                  <Typography variant="body2" color="textSecondary">
                    Date d'échéance :{" "}
                    {payment
                      ? new Date(payment.dueDate).toLocaleDateString() // Utilisation de schedule.dueDate
                      : "Non spécifiée"}
                  </Typography>

                  <Typography variant="subtitle1">
                    Montant: {(payment?.amount / 100).toFixed(2)} €{" "}
                    {/* Conversion en euros */}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  {payment.paidDate ? (
                    <Typography variant="body2" color="textSecondary">
                      Payé le :{" "}
                      {new Date(payment.paidDate).toLocaleDateString()}
                    </Typography>
                  ) : (
                    <TextField
                      type="date"
                      name={`installmentSchedule[${index}].paidDate`} // Utilisation du `name` pour lier à Formik
                      placeholder="yyyy-mm-dd"
                      fullWidth
                      onChange={handleChange}
                      onBlur={handleBlur}
                      value={payment.paidDate || ""}
                    />
                  )}
                  {/* Bouton pour remettre à zéro la date de paiement */}
                  {payment.paidDate && (
                    <Button
                      variant="outlined"
                      color="error"
                      onClick={() => {
                        const event = {
                          target: {
                            name: `installmentSchedule[${index}].paidDate`,
                            value: "",
                          },
                          persist: () => {},
                        } as React.ChangeEvent<any>;
                        handleChange(event);
                      }} // Remettre à zéro
                      sx={{ mt: 1 }} // Marges pour espacer le bouton
                    >
                      Remettre à zéro
                    </Button>
                  )}
                </Grid>
              </Grid>
            );
          })}
        </Box>
      </Paper>
    </Grid>
  );
};

export default PaymentDetails;
