import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import '../editProduct/leaflet.css';

const MapUpdater = ({ latitude, longitude }) => {
    const map = useMap();
    useEffect(() => {
        map.setView([latitude, longitude]);
    }, [latitude, longitude, map]);

    return null;
};

const markerIcon = new L.Icon({
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
    shadowSize: [41, 41]
});

const MapComponent = ({longitude, latitude, children}) => {
    return (
        <div style={{position: "relative", height: "250px", width: "100%"}}>
          <MapContainer center={[latitude, longitude]} zoom={25} style={{height: '100%', width: '100%'}}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
              {
                  latitude && longitude &&
                      <Marker key={`${latitude}-${longitude}`} position={[latitude, longitude]} icon={markerIcon}>
                          <Popup>Paris, France</Popup>
                      </Marker>
              }
  
              <MapUpdater longitude={longitude} latitude={latitude}/>
          </MapContainer>
  
          <div className="coordinated">
            <div className={"coordinated-title"}>
              Données GPS <span style={{fontSize: "10px"}}>(approximation)</span>
            </div>
            <div className={"coordinated-value"}>
                {children}
            </div>
          </div>
        </div>
    );
};


export default MapComponent;