import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import AddIcon from "@mui/icons-material/Add";
import { tokens } from "../../theme";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { Table } from "../../components/table/Table";
import { ToastContainer } from "react-toastify";
import Dashboard from "../dashboard/DashboardIndex.tsx";
import { Link } from "react-router-dom";
import { PreviewProductModal } from "../product/previewProductPDF/previewProductModal";
import { toast } from "react-toastify";

const Product = () => {
  const store = useGlobalStore();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [open, setOpen] = useState(false);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [productsData, setProductsData] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [locationDates, setLocationDates] = useState({});
  const [extraLocations, setExtraLocations] = useState([]);
  const [isInitialLoading, setIsInitialLoading] = useState(true);

  // Fetching products and customers
  const fetchProducts = async () => {
    try {
      const result = await store.product.getProducts();
      if (result.success) {
        const remapData = (data) => {
          return data
            .filter((product) => !product.deleted)
            .map((product) => ({
              id: product.id,
              ref: product.ref,
              city: product.productLocalisation?.city,
              address: product.productLocalisation?.address,
              name: product.name,
              advertisingLocations: product.AdvertisingLocation.map(
                (location) => ({
                  ...location,
                  productName: product.name,
                })
              ),
              userDetails: product.userDetails,
              allCurrentHistoryAdvertisingLocations:
                product.allCurrentHistoryAdvertisingLocations || [],
            }));
        };
        const remappedProductsData = remapData(result.data);
        setProductsData(remappedProductsData);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    }
  };

  const fetchAllCustomers = async () => {
    try {
      const result = await store.customer.getCustomers();
      if (result.success) {
        setCustomersList(result.data);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des clients :", error);
    }
  };

  const handleClickOpen = async (product) => {
    try {
      if (!product) {
        toast.error("Données du produit manquantes");
        return;
      }

      // Grouper les historiques par ID d'emplacement
      const groupedLocations =
        product.allCurrentHistoryAdvertisingLocations.reduce((acc, history) => {
          const locationId = history.AdvertisingLocation?.id;
          if (!acc[locationId]) {
            acc[locationId] = {
              id: locationId,
              productName: product.name,
              historyAdvertisingLocations: [],
            };
          }
          acc[locationId].historyAdvertisingLocations.push({
            customerId: history.customerId?.["@id"],
            customerName: history.customerCompanyName,
            startDate: history.startDate,
            endDate: history.endDate,
          });
          return acc;
        }, {});

      // Convertir l'objet groupé en tableau
      const activeLocations = Object.values(groupedLocations);

      setSelectedProduct(product);
      setSelectedCustomers(activeLocations);
      setSelectedLocation(null);
      setOpen(true);
    } catch (error) {
      console.error("Erreur lors de l'ouverture:", error);
      toast.error("Erreur lors de l'ouverture des détails");
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedLocation(null);
  };

  const handleOpenModal = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  useEffect(() => {
    const initializeData = async () => {
      setIsInitialLoading(true);
      try {
        await Promise.all([fetchProducts(), fetchAllCustomers()]);
      } catch (error) {
        console.error("Erreur lors du chargement initial:", error);
      } finally {
        setIsInitialLoading(false);
      }
    };

    initializeData();
  }, [store.product]);

  const columns = [
    {
      field: "ref",
      headerName: "REFERENCE",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "commercial",
      headerName: "NOM DU COMMERCIAL",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        const user = params.row.userDetails;
        const fullName = user ? `${user.firstName} ${user.lastName}` : "";
        return <Typography>{fullName}</Typography>;
      },
    },
    {
      field: "productName",
      headerName: "NOM DU PRODUIT",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => {
        const productName = params.row.name || "";
        console.log(productName)
        return <Typography>{productName}</Typography>;
      },
    },
    {
      field: "permission",
      headerName: "EST UTILISÉ",
      headerAlign: "center",
      align: "center",
      flex: 1,
      renderCell: (params) => {
        const { permission } = params.row;
        const activeLocationCount =
          params.row.allCurrentHistoryAdvertisingLocations?.filter(
            (location) => location.currentlyActive === true
          ).length || 0;

        let backgroundColor;
        if (activeLocationCount === 1) {
          backgroundColor = colors.yellowAccent[600];
        } else if (activeLocationCount === 2) {
          backgroundColor = colors.orangeAccent[300];
        } else if (activeLocationCount >= 3) {
          backgroundColor = colors.redAccent[600];
        } else {
          backgroundColor = colors.grey[700];
        }

        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={backgroundColor}
            borderRadius="4px"
            onClick={() => handleClickOpen(params.row)}
            sx={{ cursor: "pointer" }}
          >
            {permission === "ROLE_ADMIN" && <AdminPanelSettingsOutlinedIcon />}
            {permission === "ROLE_MANAGER" && <SecurityOutlinedIcon />}
            {permission === "ROLE_USER" && <LockOpenOutlinedIcon />}

            <Typography color={"#fffff"} sx={{ ml: "5px", fontWeight: "bold" }}>
              Emplacements actifs : {activeLocationCount}{" "}
              {permission === "ROLE_ADMIN" && "Admin"}
              {permission === "ROLE_MANAGER" && "Manager"}
              {permission === "ROLE_USER" && "Utilisateur"}
            </Typography>
          </Box>
        );
      },
    },
    {
      headerName: "EDITER",
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 0,
      renderCell: (params) => (
        <strong>
          <Link to={`/product/edit/${params.row.id}`}>
            <Button
              variant="contained"
              size="small"
              borderRadius="5px"
              style={{
                backgroundColor: colors.grey[700],
                color: colors.grey[100],
              }}
            >
              Ouvrir
            </Button>
          </Link>
        </strong>
      ),
    },
    {
      field: "pdfgenerator",
      headerName: "APERCU",
      type: "string",
      headerAlign: "center",
      align: "center",
      flex: 0,
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          size="small"
          style={{
            backgroundColor: colors.grey[700],
            color: colors.grey[100],
            textTransform: "none",
          }}
          onClick={() => handleOpenModal(row)}
        >
          Ouvrir
        </Button>
      ),
    },
  ];

  const findCustomerName = (customer) => {
    if (!customer) return "Non spécifié";
    return customer.companyName || "Non spécifié";
  };

  const LocationDisplay = ({ selectedCustomers, extraLocations }) => {
    const commonStyles = {
      width: "30%",
    };

    return (
      <Box>
        {selectedCustomers.length > 0 && (
          <Typography
            variant="h6"
            sx={{ mb: 2, justifyContent: "center", display: "flex" }}
          >
            Nom du produit: {selectedCustomers[0].productName}
          </Typography>
        )}

        {selectedCustomers.map((location, index) => {
          return (
            <Box key={`${location.id}-${index}`} sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ mb: 1 }}>
                Emplacement {index + 1}
              </Typography>

              {location.historyAdvertisingLocations.map(
                (history, historyIndex) => (
                  <Box
                    key={`${location.id}-${historyIndex}`}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 2,
                      width: "100%",
                      mb: 1,
                      "& .MuiFormControl-root": commonStyles,
                    }}
                  >
                    <FormControl sx={commonStyles} disabled>
                      <InputLabel>Client</InputLabel>
                      <Select value={history.customerId || ""} label="Client">
                        <MenuItem value={history.customerId}>
                          {history.customerName || "Non spécifié"}
                        </MenuItem>
                      </Select>
                    </FormControl>

                    <TextField
                      label="Début"
                      type="date"
                      sx={commonStyles}
                      value={history.startDate?.split("T")[0] || ""}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />

                    <TextField
                      label="Fin"
                      type="date"
                      sx={commonStyles}
                      value={history.endDate?.split("T")[0] || ""}
                      InputLabelProps={{ shrink: true }}
                      disabled
                    />
                  </Box>
                )
              )}
            </Box>
          );
        })}

        {extraLocations.map((location, index) => (
          <Box key={location.id} sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ mb: 1 }}>
              Emplacement {selectedCustomers.length + index + 1}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
              <FormControl sx={commonStyles} disabled>
                <InputLabel>Sélectionner Client</InputLabel>
                <Select value={location.customerId} label="Sélectionner Client">
                  <MenuItem value={location.customerId}>
                    {findCustomerName(location.customerId)}
                  </MenuItem>
                </Select>
              </FormControl>

              <TextField
                label="Début"
                type="date"
                sx={commonStyles}
                value={location.startDate || ""}
                InputLabelProps={{ shrink: true }}
                disabled
              />

              <TextField
                label="Fin"
                type="date"
                sx={commonStyles}
                value={location.endDate || ""}
                InputLabelProps={{ shrink: true }}
                disabled
              />
            </Box>
          </Box>
        ))}
      </Box>
    );
  };

  return (
    <Box m="20px">
      <Dashboard
        title="Produits"
        subtitle="Retrouvez toutes les produits."
        element={
          <Button href="/product/edit" variant="contained" color="secondary">
            <AddIcon />
            Ajouter
          </Button>
        }
      />

      {isInitialLoading || productsData.length === 0 ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="200px"
        >
          <CircularProgress />
        </Box>
      ) : (
        <Table Data={productsData} columns={columns} />
      )}

      <PreviewProductModal
        open={modalOpen}
        handleClose={handleCloseModal}
        selectedProduct={selectedProduct}
      />

      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{ style: { width: "750px" } }}
      >
        <DialogContent>
          <LocationDisplay
            selectedCustomers={selectedCustomers}
            customersList={customersList}
            extraLocations={extraLocations}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Fermer
          </Button>
        </DialogActions>
      </Dialog>
      <ToastContainer />
    </Box>
  );
};

export default Product;
