import { makeAutoObservable } from "mobx";
import { ApiURL } from "../../../constants/URL";

interface Product {
  id: string;
  name: string;
  price: number;
  description: string;
}

interface ProductResponse {
  success: boolean;
  data?: Product | Product[];
  error?: string;
  message?: string;
}

interface GlobalStore {
  authService: { token: string };
  isAuthenticated: boolean;
}

interface HistoryResponse {
  success: boolean;
  data?: {
    customerId: string;
    startDate: string;
    endDate: string;
    currentlyActive: boolean;
    customerCompanyName: string;
  };
  error?: string;
}

interface Customer {
  id: string;
  companyName: string;

}

interface CustomerResponse {
  success: boolean;
  data?: Customer[];
  error?: string;
  message?: string;
}

interface HistoryData {
  id: string;
  customerId: string;
  advertisingLocation: string;
  startDate: string;
  endDate: string;
}

interface User {
  id: string;
  firstName: string;
  lastName: string;
}

interface UserResponse {
  success: boolean;
  data?: User[];
  error?: string;
  message?: string;
}

class ProductService {
  globalStore: GlobalStore;
  products: Product[] = [];

  constructor(globalStore: GlobalStore) {
    makeAutoObservable(this);
    this.globalStore = globalStore;
  }

  // Typage de la fonction `handleResponse`
  async handleResponse(response: Response): Promise<ProductResponse> {
    try {
      if (response.ok) {
        const data = await response.json();
        if (data["hydra:member"] !== undefined) {
          this.products = data["hydra:member"];
          this.globalStore.isAuthenticated = true;
          return { success: true, data: this.products };
        } else {
          return { success: true, data };
        }
      } else {
        const errorData = await response.json();
        return {
          success: false,
          error: `Error with status: ${response.status}`,
          message: errorData.detail,
        };
      }
    } catch (error: any) {
      console.error("Error handling response:", error);
      return {
        success: false,
        error: "Error handling response",
      };
    }
  }

  async getProducts(): Promise<ProductResponse> {
    try {
      const response = await fetch(ApiURL.Product, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async getProductsById(id: string): Promise<ProductResponse> {
    try {
      const response = await fetch(`${ApiURL.Product}/${id}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });

      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async postProduct(productData: Record<string, any>): Promise<ProductResponse> {
    try {
      const response = await fetch(ApiURL.Product, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productData),
      });

      return await this.handleResponse(response);
    } catch (error: any) {
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async putProduct(id: string, productData: Record<string, any>): Promise<ProductResponse> {
    try {
      console.log("Envoi des données de mise à jour:", productData);

      const response = await fetch(`${ApiURL.Product}/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(productData)
      });

      console.log("Status:", response.status);
      const responseData = await response.json();
      console.log("Réponse du serveur:", responseData);

      if (response.ok) {
        return {
          success: true,
          data: responseData
        };
      } else {
        return {
          success: false,
          error: responseData.detail || responseData.message || "Erreur lors de la mise à jour",
          message: responseData.violations ? 
            responseData.violations.map((v: any) => `${v.propertyPath}: ${v.message}`).join(', ') : 
            undefined
        };
      }
    } catch (error: any) {
      console.error("Erreur complète:", error);
      return {
        success: false,
        error: `Erreur réseau ou de parsing: ${error.message}`,
      };
    }
  }
  
  async getHistoryDetails(historyId: string) {
    try {
      const response = await fetch(`${ApiURL.AdvertisingLocationHistory}/${historyId}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });

      const data = await response.json();
      
      if (response.ok) {
        return {
          success: true,
          data: {
            ...data,
            customerCompanyName: data.customerCompanyName || 'Non défini'
          }
        };
      } else {
        return {
          success: false,
          error: data.message || 'Une erreur est survenue'
        };
      }
    } catch (error: any) {
      console.error("Error fetching history details:", error);
      return {
        success: false,
        error: error.message
      };
    }
  }
  
  async createAdvertisingLocation(productId: string): Promise<ProductResponse> {
    try {
      const response = await fetch(`${ApiURL.AdvertisingLocations}`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          product: `/api/products/${productId}`
        }),
      });

      return await this.handleResponse(response);
    } catch (error: any) {
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async getCustomers(): Promise<CustomerResponse> {
    try {
      const response = await fetch(`${ApiURL.Customers}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        return {
          success: true,
          data: data["hydra:member"] || data
        };
      } else {
        const errorData = await response.json();
        return {
          success: false,
          error: `Error with status: ${response.status}`,
          message: errorData.detail,
        };
      }
    } catch (error: any) {
      console.error("Error fetching customers:", error);
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async saveLocationHistory(historyData: HistoryData): Promise<any> {
    try {
      console.log("Envoi des données:", historyData);
  
      const response = await fetch(`${ApiURL.AdvertisingLocationHistory}`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(historyData)
      });
  
      const data = await response.json();
      
      if (response.ok) {
        return {
          success: true,
          data: data
        };
      } else {
        return {
          success: false,
          error: data.message || 'Une erreur est survenue',
          isAvailabilityError: data.isAvailable === false
        };
      }
    } catch (error: any) {
      console.error("Error saving history:", error);
      return {
        success: false,
        error: error.message,
        isParsingError: true
      };
    }
  }

  async getUsers(): Promise<UserResponse> {
    try {
      const response = await fetch(`${ApiURL.Users}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        return {
          success: true,
          data: data["hydra:member"] || data
        };
      } else {
        const errorData = await response.json();
        return {
          success: false,
          error: `Error with status: ${response.status}`,
          message: errorData.detail,
        };
      }
    } catch (error: any) {
      console.error("Error fetching users:", error);
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  async updateLocalisation(id: string, localisationData: Record<string, any>): Promise<ProductResponse> {
    try {
      const response = await fetch(`${ApiURL.ProductLocalisation}/${id}`, {
        method: "PUT",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(localisationData)
      });

      const data = await response.json();
      
      if (response.ok) {
        return {
          success: true,
          data: data
        };
      } else {
        return {
          success: false,
          error: data.detail || "Erreur lors de la mise à jour de la localisation"
        };
      }
    } catch (error: any) {
      return {
        success: false,
        error: `Erreur réseau ou de parsing: ${error.message}`
      };
    }
  }

  async getAdvertisingLocationDetails(locationId: string) {
    try {
      const response = await fetch(`${ApiURL.AdvertisingLocations}/${locationId}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const data = await response.json();
        
        if (data.historyAdvertisingLocations) {
          const historyPromises = data.historyAdvertisingLocations.map(async (historyIri: string) => {
            const historyId = historyIri.split('/').pop();
            if (!historyId) return null;
            
            const historyResult = await this.getHistoryDetails(historyId);
            return historyResult.success ? historyResult.data : null;
          });

          const histories = (await Promise.all(historyPromises)).filter(h => h !== null);
          
          return {
            success: true,
            data: {
              ...data,
              historyAdvertisingLocations: histories
            }
          };
        }

        return { success: true, data };
      } else {
        return {
          success: false,
          error: "Erreur lors de la récupération des détails de la localisation"
        };
      }
    } catch (error: any) {
      console.error("Erreur lors de la récupération des détails:", error);
      return {
        success: false,
        error: error.message
      };
    }
  }

  async checkAdvertisingLocationAvailability(historyData: HistoryData): Promise<any> {
    try {
      console.log("Vérification de la disponibilité:", historyData);
      
      const response = await fetch(`${ApiURL.AdvertisingLocationHistory}/create`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: historyData.customerId,
          advertisingLocation: historyData.advertisingLocation,
          startDate: new Date(historyData.startDate).toISOString(),
          endDate: new Date(historyData.endDate).toISOString()
        })
      });

      const data = await response.json();
      
      if (response.ok) {
        return {
          success: true,
          data: data
        };
      } else {
        return {
          success: false,
          data: data,
          error: data.message || 'Une erreur est survenue lors de la vérification'
        };
      }
    } catch (error: any) {
      console.error("Erreur lors de la vérification:", error);
      return {
        success: false,
        error: error.message,
        isParsingError: true
      };
    }
  }

  async updateHistoryAdvertisingLocation(historyData: HistoryData): Promise<any> {
    try {
      console.log("Mise à jour de l'historique:", historyData);
      
      const response = await fetch(`${ApiURL.AdvertisingLocationHistory}/${historyData.id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          customerId: historyData.customerId,
          advertisingLocation: historyData.advertisingLocation,
          startDate: historyData.startDate,
          endDate: historyData.endDate
        })
      });

      const data = await response.json();
      
      if (response.ok) {
        return {
          success: true,
          data: data
        };
      } else {
        return {
          success: false,
          error: data.message || 'Une erreur est survenue lors de la mise à jour'
        };
      }
    } catch (error: any) {
      console.error("Erreur lors de la mise à jour:", error);
      return {
        success: false,
        error: error.message
      };
    }
  }

  async softDeleteProduct(id: string): Promise<ProductResponse> {
    try {
      const response = await fetch(`${ApiURL.Product}/${id}/soft-delete`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({})
      });

      return await this.handleResponse(response);
    } catch (error: any) {
      return {
        success: false,
        error: `Erreur réseau ou de parsing: ${error.message}`,
      };
    }
  }
}

export default ProductService;
