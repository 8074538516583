import "./App.css";
import React, { FC } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Topbar from "./pages/global/Topbar";
import MySidebar from "./pages/global/MySidebar";
import Login from "./pages/login/Login";
import Customer from "./pages/customer/customerIndex";
import PrivateRoute from "./store/privateRoutes/PrivateRoutes.tsx";
import { ColorModeContext, useMode } from "./theme";
import { useGlobalStore } from "./store/globalStore.tsx";
import { ThemeProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import { Toast } from "./components/toast/Toast";
import Invoices from "./pages/invoice/InvoiceIndex.tsx";
import EditInvoice from "./pages/invoice/editInvoice/EditInvoice.tsx";
import IndexPage from "./pages/index/indexPage";
import EditCustomer from "./pages/customer/editCustomer/EditCustomer";
import Calendar from "./pages/calendar/calendar";
import EditUser from "./pages/user/editUser/editUser";
import Product from "./pages/product/productIndex";
import EditProduct from "./pages/product/editProduct/EditProduct";
import User from "./pages/user/user";
import FileUpload from "./pages/test/FileUpload";

const App: FC = () => {
  const [theme, colorMode] = useMode();
  const globalStore = useGlobalStore();
  const isAuth = globalStore.authService.isAuth();
  const user = globalStore.authService.user;

  if (!isAuth) {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Toast />
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="*" element={<Navigate to="/login" />} />
          </Routes>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  } else {
    return (
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <div className="App">
            {isAuth && <MySidebar />}
            <main className="content">
              {isAuth && <Topbar />}
              <Routes>
                <Route
                  path="/"
                  element={
                    <PrivateRoute>
                      <IndexPage />
                    </PrivateRoute>
                  }
                />
                <Route path="/dashboard" element={<PrivateRoute />} />
                <Route
                  path="/customer"
                  element={
                    <PrivateRoute>
                      <Customer />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/customer/edit/:id"
                  element={
                    <PrivateRoute>
                      <EditCustomer />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/invoice"
                  element={
                    <PrivateRoute>
                      <Invoices />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/invoice/edit/:id?"
                  element={
                    <PrivateRoute>
                      <EditInvoice />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/user"
                  element={
                    <PrivateRoute>
                      <User />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/user/add"
                  element={
                    <PrivateRoute>
                      <EditUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/user/edit/:id?"
                  element={
                    <PrivateRoute>
                      <EditUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/product"
                  element={
                    <PrivateRoute>
                      <Product />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/product/edit/:id?"
                  element={
                    <PrivateRoute>
                      <EditProduct />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/product/create"
                  element={
                    <PrivateRoute>
                      <EditProduct />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/calendar"
                  element={
                    <PrivateRoute>
                      <Calendar />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/test"
                  element={
                    <PrivateRoute>
                      <FileUpload />
                    </PrivateRoute>
                  }
                />
              </Routes>
            </main>
          </div>
        </ThemeProvider>
      </ColorModeContext.Provider>
    );
  }
};

export default App;
