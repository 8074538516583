import React from "react";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import "./editInvoice.css";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Select,
  TextField,
  MenuItem,
  Grid,
  CircularProgress,
  SelectChangeEvent,
} from "@mui/material";
import { textFieldStyleModal } from "../../../components/style/componentStyle";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import Dashboard from "../../dashboard/DashboardIndex.tsx";
import { json, useParams } from "react-router-dom";
import { Formik } from "formik";
import { formatInputDate, formatUserDate } from "../../../utils/formatDate";
import { toast } from "react-toastify";
import { Customer, InvoiceLine, Invoice, checkoutSchema } from "./types.ts";
import EditInvoiceSidebar from "./EditInvoiceSidebar.tsx";

const EditInvoice = () => {
  // Récupération du store global
  const store = useGlobalStore();

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  // État pour stocker la liste des clients
  const [customers, setCustomers] = useState<Customer[]>([]);

  // État pour stocker les taux de taxe
  const [taxRates, setTaxRates] = useState<{ id: number; rate: number }[]>([]); // Typage explicite pour taxRates

  // État pour gérer le chargement des données
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // État pour le numéro de la facture
  const [number, setNumber] = useState<string>("");

  // État pour le client sélectionné
  const [selectedCustomer, setSelectedCustomer] = useState<Customer | null>(
    null
  );

  // État pour la date de facturation
  const [issuedAt, setIssuedAtDate] = useState<string>(
    new Date().toISOString().split("T")[0]
  );

  // État pour les conditions de paiement (nombre de jours)
  const [paymentDaysCondition, setPaymentDaysCondition] = useState<string>("");

  // État pour la date d'échéance
  const [dueDate, setDueDate] = useState<string | null>(null);

  // État pour le message de la facture
  const [message, setMessage] = useState<string>(
    "Cevep vous remercie pour votre confiance salut"
  );

  // Récupération de l'ID de la facture à partir des paramètres de l'URL
  const { id } = useParams<{ id: string }>(); // Typage des paramètres

  // État pour la longueur du message
  const [messageLength, setMessageLength] = useState<number>(0);

  // État pour le numéro de facture généré
  const [generatedInvoiceNumber, setGeneratedInvoiceNumber] =
    useState<string>("");

  // On set les lignes de facture par défaut

  const [invoiceLines, setInvoiceLines] = useState<InvoiceLine[]>([
    {
      description: "",
      quantity: 1,
      price: 0,
      position: 1,
      taxRateId: 0,
      taxRateValue: 0,
      product: "",
    },
  ]);

  const initialValues = {
    number: generatedInvoiceNumber || number,
    customerId: selectedCustomer ? selectedCustomer.id : "",
    issuedAt: issuedAt,
    message: message,
    paymentDaysCondition: paymentDaysCondition || 0,
    lines: invoiceLines,
  };

  // État pour stocker les paramètres de la sidebar
  const [sidebarSettings, setSidebarSettings] = useState({
    invoiceType: "Simple",
    frequency: "",
    duration: null,
    sendMethod: "Créer seulement",
    durationType: "Limité",
    depositAmount: 0,
    installments: 1,
    hasDeposit: false,
    hasInstallments: false,
    paymentMethod: "virement",
  });

  const addNewLine = () => {
    setInvoiceLines((prevLines) => [
      ...prevLines,
      {
        description: "",
        quantity: 0,
        price: 0,
        position: prevLines.length + 1,
        taxRateId: 0,
        taxRateValue: 0,
        priceTotal: 0,
        product: "",
      },
    ]);
  };

  // Nouvelle fonction pour supprimer une ligne de facture
  const removeLine = (index) => {
    setInvoiceLines((prevLines) => prevLines.filter((_, i) => i !== index));
  };

  // On gere la soumission du formulaire

  const handleValidation = (values) => {
    const errors: string[] = [];

    // Vérifiez si un client est sélectionné
    if (!selectedCustomer) {
      errors.push("Vous devez sélectionner un client.");
    }

    // Vérifiez si le numéro de facture est renseigné
    if (!values.number) {
      errors.push("Le numéro de facture est requis.");
    }

    // Vérifiez si la date de facturation est renseignée
    if (!values.issuedAt) {
      errors.push("La date de facturation est requise.");
    }

    // Si des erreurs sont trouvées, afficher un toast et retourner false
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return false; // Indiquer qu'il y a une erreur
    }

    // Vérifiez que chaque ligne de facture a un taux de TVA
    for (const line of values.lines) {
      if (!line.taxRateId) {
        errors.push(
          `La ligne "${line.description}" doit avoir un taux de TVA.`
        );
      }
    }

    return true; // Indiquer que la validation est réussie
  };

  const handleInvoiceSubmit = (data) => {
    console.log("Données de paiement:", data);

    // Déterminer si la facture est simple ou récurrente
    const isRecurring = data.invoice_type === "recurring";

    // Mettre à jour les valeurs initiales avec les données de paiement

    const updatedValues = {
      ...initialValues,
      paiement: {
        payment_method: data.payment_method,
        send_method: data.send_method,
        discount_amount: data.discount_amount
          ? parseFloat(data.discount_amount)
          : null,
        installments: data.installments || 1,
      },
      invoiceGenerationSchedule: isRecurring
        ? {
            startDate: initialValues.issuedAt, // Utilisez la date d'émission
            duration: data.duration || 1,
            frequency: data.frequency || "Mensuelle",
            isInfinite: data.is_infinite || false,
          }
        : null, // Pas de planification pour les factures simples
    };
    
    handleSubmit(updatedValues);
  };

  const handleSubmit = async (values) => {
    // Vérifiez la validation des champs
    if (!handleValidation(values)) {
      return; // Si la validation échoue, ne pas soumettre le formulaire
    }
    try {
      let result;
      if (id) {
        result = await store.invoice.putInvoice(id, values); // Assurez-vous d'avoir cette méthode dans votre store
        if (result.success) {
          toast.success("La facture a bien été mise à jour.");
        } else {
          toast.error("Des erreurs sont présentes dans le formulaire.");
        }
      } else {
        // Création d'une nouvelle facture
        result = await store.invoice.postInvoice(values);
        if (result.success) {
          toast.success(
            "La facture a bien été créée et enregistrée dans les brouillons."
          );
        } else {
          toast.error("Des erreurs sont présentes dans le formulaire.");
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);
      toast.error("Une erreur est survenue lors de l'envoi des données.");
    } finally {
    }
  };

  const handleGenerateInvoiceNumber = async () => {
    if (!id) {
      // Vérifiez si l'ID n'est pas présent dans l'URL
      const format = "YEAR/COUNT"; // Le format que vous souhaitez utiliser
      const prefix = "FA"; // Le préfixe que vous souhaitez utiliser

      const result = await store.invoice.generateInvoiceNumber(format, prefix);
      console.log("Résultat de la génération du numéro de facture:", result);
      if (result.success) {
        const invoiceNumber = result.data[0]; // Accéder au premier élément du tableau Proxy
        setGeneratedInvoiceNumber(invoiceNumber);
      } else {
        toast.error("Erreur lors de la génération du numéro de facture");
      }
    }
  };

  useEffect(() => {
    handleGenerateInvoiceNumber(); // Appeler la méthode pour générer un numéro de facture si ID n'est pas présent
  }, [id]);

  const fetchInvoiceData = async (invoiceId) => {
    setIsLoading(true); // Activer l'état de chargement
    try {
      // Appel à la méthode du store pour récupérer les informations de la facture
      const result = await store.invoice.getInvoiceById(invoiceId);

      if (result.success) {
        const invoice = result.data;
        // Vérifiez que invoice est un objet et non un tableau

        if (!invoice) {
          setSidebarSettings({
            invoiceType: invoice.invoice_type || "Simple",
            frequency: invoice.frequency || "Mensuelle",
            duration: invoice.duration || 5,
            sendMethod: invoice.send_method || "Créer seulement",
            durationType: invoice.is_infinite ? "Illimité" : "Limité",
            depositAmount: invoice.discount_amount || 0,
            installments: invoice.installments || 1,
            hasDeposit: invoice.discount_amount > 0,
            hasInstallments: invoice.installments > 1,
            paymentMethod: invoice.payment_method || "virement",
          });
        }
        if (invoice && typeof invoice === "object" && !Array.isArray(invoice)) {
          if (Array.isArray(invoice.invoiceLines)) {
            setInvoiceLines(
              invoice.invoiceLines.map((line, index) => ({
                id: line.id || 0,
                description: line.description || "",
                quantity: line.quantity || 0,
                price: line.price || 0,
                position: index + 1,
                taxRateId: line.taxRate ? line.taxRate.id : 0,
                taxRateValue: line.taxRate ? line.taxRate.amount : 0,
                product: line.product || "",
              }))
            );
          } else {
            console.error(
              "La propriété 'invoiceLines' n'est pas un tableau:",
              invoice
            );
            setInvoiceLines([]);
          }

          console.log("Données de la facture:", invoice);
          // Gérer les autres propriétés
          setNumber(invoice.number || "");
          setIssuedAtDate(formatInputDate(new Date(invoice.issuedAt)));
          setMessage(invoice.message || "");
          setPaymentDaysCondition(invoice.paymentDaysCondition || 0);

          // Assurez-vous que 'customer' est défini avant d'accéder
          if (invoice.customer) {
            setSelectedCustomer(invoice.customer);
          } else {
            console.error("Aucun client trouvé dans la facture:", invoice);
          }
        } else {
          console.error(
            "L'objet 'invoice' n'est pas défini ou est un tableau:",
            invoice
          );
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de la facture :", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Appel de la fonction de récupération de données lorsque l'ID est présent
  useEffect(() => {
    if (id) {
      fetchInvoiceData(id); // Charger les données si un ID est passé dans l'URL
    }
  }, [id]);

  // Recuperation des données

  const fetchCustomers = async () => {
    setIsLoading(true);
    try {
      const result = await store.customer.getCustomers();

      if (result.success) {
        const customersArray = Array.isArray(result.data)
          ? result.data
          : [result.data];

        setCustomers(customersArray);
        if (id) {
          const selectedCustomer = customersArray.find((c) => c.id == id);
          setSelectedCustomer(selectedCustomer);
        }
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchTaxRate = async () => {
    try {
      const result = await store.taxeRate.getTaxeRate();

      if (result.success) {
        // Extraire les taux de taxes de result.data
        const taxRates = result.data.map((taxRate) => ({
          id: taxRate.id,
          rate: taxRate.amount,
        }));

        setTaxRates(taxRates);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Récupérer les données lorsque l'ID est présent
  useEffect(() => {
    if (id) {
      fetchInvoiceData(id);
    }
  }, [id]);

  // Récupérer la longueur du message
  useEffect(() => {
    setMessageLength(message.length);
  }, [message]);

  // Initialiser la date de facturation
  useEffect(() => {
    const today = new Date();
    const dateToString = formatInputDate(today);
    setIssuedAtDate(dateToString);
  }, []);

  // Calculer la date d'échéance
  useEffect(() => {
    const newDate = new Date(issuedAt);
    newDate.setDate(newDate.getDate() + Number(paymentDaysCondition));
    setDueDate(formatUserDate(newDate));
  }, [issuedAt, paymentDaysCondition]);

  // Récupérer les taux de taxe
  useEffect(() => {
    if (taxRates.length === 0) {
      fetchTaxRate();
    }
  }, [taxRates]);

  // Récupérer les clients
  useEffect(() => {
    if (customers.length === 0) {
      fetchCustomers();
    }
  }, [customers]);

  useEffect(() => {
    if (customers.length === 0) {
      fetchCustomers();
    }
  }, [customers]);

  // Fonction pour gérer le changement des jours de paiement
  const handleDateDueChange = (event: SelectChangeEvent<string | number>) => {
    setPaymentDaysCondition(event.target.value as string);
  };

  // Fonction pour gérer le changement de la date d'émission
  const handleIssuedAt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIssuedAtDate(event.target.value);
  };

  // Fonction pour gérer le changement de client
  const handleCustomerChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const selectedCustomerId = event.target.value as string;
    const customer = customers.find((c) => c.id === selectedCustomerId);
    if (customer) {
      setSelectedCustomer(customer);
    } else {
      console.error("Aucun client trouvé avec l'ID:", selectedCustomerId);
      setSelectedCustomer(null);
    }
  };

  // Réinitialiser la sélection du client
  const resetSelection = () => {
    setSelectedCustomer(null);
  };

  // Gérer les changements dans les lignes de facture
  const handleLineChange = (
    event:
      | React.ChangeEvent<HTMLInputElement | { value: unknown }>
      | SelectChangeEvent<number>,
    index: number,
    field: string
  ) => {
    const newInvoiceLines = [...invoiceLines];
    if (field === "price") {
      // Si c'est le prix, convertissez les euros en centimes
      const valueAsFloat = parseFloat(event.target.value as string);
      const valueAsCentimes = Math.round(valueAsFloat * 100); // Convertir les euros en centimes
      newInvoiceLines[index][field] = !isNaN(valueAsCentimes)
        ? valueAsCentimes
        : 0;
    } else if (field === "quantity") {
      // Si c'est la quantité, on garde la valeur en entier
      const valueAsInt = parseInt(event.target.value as string, 10);
      newInvoiceLines[index][field] = !isNaN(valueAsInt) ? valueAsInt : 0;
    } else {
      // Pour les autres champs (description, taxRateId, etc.)
      newInvoiceLines[index][field] = event.target.value as string;
    }
    if (field === "taxRateId") {
      const taxRateId = event.target.value as number;
      const selectedTaxRate = taxRates.find((rate) => rate.id === taxRateId);
      if (selectedTaxRate) {
        newInvoiceLines[index].taxRateId = selectedTaxRate.id;
        newInvoiceLines[index].taxRateValue = selectedTaxRate.rate;
      }
    }
    setInvoiceLines(newInvoiceLines);
  };
  // Calculer le total de la facture
  const calculateTotal = () => {
    return invoiceLines.reduce((total, line) => {
      const taxRateDecimal = line.taxRateValue / 100;
      const priceInEuros = line.price / 100; // Convert price from centimes to euros
      return total + line.quantity * priceInEuros * (1 + taxRateDecimal);
    }, 0);
  };

  const handleTTCChange = (e, index) => {
    const prixTTC = parseFloat(e.target.value);
    const taxRateId = invoiceLines[index].taxRateId; // Récupérer l'ID du taux de taxe
    const taxRate = taxRates.find((rate) => rate.id === taxRateId); // Trouver le taux de taxe correspondant
    if (taxRate) {
      const taxRateDecimal = taxRate.rate / 100; // Utiliser le taux de taxe trouvé
      const prixHT = prixTTC / (1 + taxRateDecimal);
      handleLineChange({ target: { value: prixHT } }, index, "price");
    }
  };

  // Fonction pour afficher les prix en euros (avec conversion depuis centimes)
  const displayPriceInEuros = (priceInCentimes) => {
    return (priceInCentimes / 100).toFixed(2); // Convertir centimes en euros
  };

  if (isLoading) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  return (
    <div>
      <EditInvoiceSidebar onSubmit={handleInvoiceSubmit}  settings={sidebarSettings}/>
      <Box m="20px">
        <Dashboard
          title={"Factures"}
          subtitle={"Retrouvé toutes vos factures"}
        />
      </Box>
      <div className="invoice-container">
        <Formik
          onSubmit={(values, actions) => handleSubmit(values, actions)}
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={checkoutSchema}
        >
          {({ values, errors, touched, handleChange, handleSubmit, dirty }) => (
            <form id="monFormulaire" onSubmit={handleSubmit}>
              <h1>Facture</h1>
              <div>
                <Box m={2}>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                      <Box>
                        <strong>Numéro de Facture:</strong>
                      </Box>
                      <Box>
                        <TextField
                          sx={textFieldStyleModal}
                          value={values.number}
                          onChange={handleChange}
                          fullWidth
                        />
                      </Box>
                      <Box>
                        <strong>Date de facturation:</strong>
                      </Box>
                      <Box>
                        <TextField
                          sx={textFieldStyleModal}
                          type="date"
                          name="issuedAt"
                          value={values.issuedAt}
                          onChange={handleIssuedAt}
                          fullWidth
                        />
                      </Box>

                      <Box>
                        <strong>Conditions de paiement:</strong>
                      </Box>
                      <Box>
                        <Select
                          value={values.paymentDaysCondition}
                          displayEmpty
                          sx={textFieldStyleModal}
                          fullWidth
                          onChange={handleDateDueChange}
                          name="paymentDaysCondition"
                          inputProps={{ "aria-label": "Without label" }}
                        >
                          <MenuItem value={0}>0</MenuItem>
                          <MenuItem value={7}>7</MenuItem>
                          <MenuItem value={14}>14</MenuItem>
                          <MenuItem value={30}>30</MenuItem>
                          <MenuItem value={60}>60</MenuItem>
                          <MenuItem value={90}>90</MenuItem>
                        </Select>
                      </Box>
                      <Box>
                        <strong>Date d'émission de la facture :</strong>
                      </Box>
                      <Box>
                        <TextField sx={textFieldStyleModal} value={dueDate} />
                      </Box>
                    </Grid>

                    <Grid item xs={6}>
                      <Box>
                        <strong>Message ({messageLength} /3000):</strong>
                      </Box>
                      <Box>
                        <TextField
                          sx={textFieldStyleModal}
                          multiline
                          rows={4}
                          variant="outlined"
                          value={values.message}
                          onChange={(e) => {
                            setMessageLength(e.target.value.length);
                            setMessage(e.target.value);
                          }}
                          size="small"
                          inputProps={{ maxLength: 3000 }}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <hr />
              </div>
              <div className="invoice-header">
                <Box sx={{ flexGrow: 1 }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      {!selectedCustomer && (
                        <Select
                          value={selectedCustomer ? selectedCustomer.id : ""}
                          onChange={handleCustomerChange}
                          displayEmpty
                          sx={textFieldStyleModal}
                          inputProps={{ "aria-label": "Without label" }}
                          error={
                            touched.customerSelect && !!errors.customerSelect
                          }
                          helpertext={
                            touched.customerSelect && errors.customerSelect
                          }
                        >
                          <MenuItem value="" disabled>
                            Sélectionner un client
                          </MenuItem>
                          {customers.map((customer) => (
                            <MenuItem key={customer.id} value={customer.id}>
                              {customer.companyName}
                            </MenuItem>
                          ))}
                        </Select>
                      )}
                      {selectedCustomer && (
                        <div>
                          <h3>Informations sur le Client</h3>
                          <Button onClick={resetSelection}>
                            Changer de client
                          </Button>
                          <p>Nom: {selectedCustomer.companyName}</p>
                          <p>Adresse: {selectedCustomer.address}</p>
                          {/* Affichez d'autres informations sur le client ici */}
                        </div>
                      )}
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <div>
                        <p>
                          <strong>C.E.V.E.P</strong>
                        </p>
                        <p>Adresse: 16 rue de valencienne</p>
                        <p>59000, Lille</p>
                        {/* Affichez d'autres informations sur le client ici */}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              </div>
              <table className="invoice-table">
                <thead>
                  <tr>
                    <th>Description</th>
                    <th>Quantité</th>
                    <th>Prix Unitaire</th>
                    <th>TVA</th>
                    <th>Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {invoiceLines.map((line, index) => (
                    <tr key={index}>
                      <td>
                        <TextField
                          sx={textFieldStyleModal}
                          variant="outlined"
                          value={line.description}
                          onChange={(e) =>
                            handleLineChange(e, index, "description")
                          }
                          size="small"
                        />
                      </td>
                      <td>
                        <TextField
                          sx={textFieldStyleModal}
                          type="number"
                          variant="outlined"
                          value={line.quantity}
                          onChange={(e) =>
                            handleLineChange(e, index, "quantity")
                          }
                          size="small"
                        />
                      </td>
                      <td>
                        <TextField
                          sx={textFieldStyleModal}
                          type="number"
                          variant="outlined"
                          value={displayPriceInEuros(line.price)}
                          onChange={(e) => handleLineChange(e, index, "price")}
                          size="small"
                        />
                      </td>
                      <td>
                        <Select
                          sx={textFieldStyleModal}
                          size="small"
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={line.taxRateId || ""}
                          onChange={(e) =>
                            handleLineChange(e, index, "taxRateId")
                          }
                          label="Taux de TVA"
                        >
                          <MenuItem value="">
                            <em>Aucun</em>
                          </MenuItem>
                          {taxRates.map((rate, index) => (
                            <MenuItem key={index} value={rate.id}>
                              {rate.rate}%
                            </MenuItem>
                          ))}
                        </Select>
                      </td>
                      <td>
                        <TextField
                          sx={textFieldStyleModal}
                          type="number"
                          variant="outlined"
                          value={(
                            (line.price / 100) * // convert centimes to euros
                            line.quantity *
                            (1 + line.taxRateValue / 100)
                          ).toFixed(2)}
                          onChange={(e) => handleTTCChange(e, index)}
                          size="small"
                        />
                      </td>
                      <td>
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => removeLine(index)} // Appeler la fonction pour supprimer la ligne
                        >
                          <DeleteIcon />
                        </Button>
                      </td>
                    </tr>
                  ))}
                  <tr>
                    <td colSpan="5">
                      <Box marginTop={"20px"}>
                        <Button variant="contained" onClick={addNewLine}>
                          Ajouter une ligne
                        </Button>
                      </Box>
                    </td>
                  </tr>
                </tbody>
              </table>
              <Box className="invoice-total">
                <strong>Total TTC:</strong> {calculateTotal().toFixed(2)} €
              </Box>
              <Box className="invoice-footer">
                <p>Merci pour votre entreprise !</p>
              </Box>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default EditInvoice;
