//const baseURL = 'https://localhost:8000';
const baseURL = 'https://sym.cevepinterface.fr';

export const ApiURL =  {
    jwtLogin: baseURL + '/api/login_check',
    Customers: baseURL + '/api/customers',
    Users: baseURL + '/api/users',
    Commercial: baseURL + '/api/commercials',
    Product: baseURL + '/api/products',
    Invoices : baseURL + '/api/invoices',
    InvoicesById : baseURL + '/api/invoices/',
    InvoicesByUserId : baseURL + '/api/invoices?customer.id=',
    taxeRates : baseURL + '/api/tax_rates',
    AdvertisingLocations: baseURL + `/api/advertising_locations`,
    AdvertisingLocationHistory: baseURL + `/api/history_advertising_locations`,
    Users: baseURL + `/api/users`,
    ProductLocalisation: baseURL + `/api/product_localisations`,
    CurrentUser : baseURL + `/api/current_user`,
}
