export const textFieldStyleModal = {
  backgroundColor: "#ffffff", // Fond clair
  "& .MuiFormLabel-root": {
    color: "#333333", // Couleur sombre pour le label
  },
  "& .MuiInputBase-root": {
    backgroundColor: "#f5f5f5", // Fond légèrement grisé
    color: "#000000", // Texte noir
    borderRadius: "4px", // Coins légèrement arrondis
    border: "1px solid #cccccc", // Bordure grise claire
    padding: "10px", // Ajout de padding pour un espace uniforme
  },
  "& .MuiInputBase-root:hover": {
    backgroundColor: "#e8e8e8", // Fond légèrement plus sombre au survol
    borderColor: "#000000", // Bordure noire au survol
  },
  "& .MuiInputBase-root.Mui-focused": {
    backgroundColor: "#ffffff", // Fond blanc lorsqu'il est focalisé
    borderColor: "#6200ee", // Bordure violette pour indiquer le focus
  },
  "& .Mui-error": {
    color: "#d32f2f", // Couleur rouge pour les erreurs
    borderColor: "#d32f2f", // Bordure rouge en cas d'erreur
  },
  "& .MuiFormHelperText-root": {
    color: "#d32f2f", // Texte rouge pour les messages d'erreur
    fontSize: "0.875rem", // Taille du texte légèrement réduite
  },
  // Styles spécifiques pour les composants Select
  "& .MuiSelect-select": {
    backgroundColor: "#f5f5f5", // Fond légèrement grisé
    color: "#000000", // Texte noir pour contraste
    borderRadius: "4px", // Coins arrondis pour le Select
    padding: "10px", // Ajout d'un padding pour alignement
  },
  "& .MuiSelect-select:hover": {
    backgroundColor: "#e8e8e8", // Fond légèrement plus sombre au survol
  },
  "& .MuiSvgIcon-root": {
    color: "#333333", // Couleur de l'icône du Select
  },
};


export const textFieldStyleClassic = {
  color: "white !important",
  marginTop: "10px",
};
