import { makeAutoObservable } from "mobx";
import { ApiURL } from "../../../constants/URL";
import GlobalStore from "../../globalStore";

class TaxeRateService {

  globalStore: GlobalStore;

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeAutoObservable(this);
  }

   taxeRates = [];

  async handleResponse(response) {
    if (response.ok) {
      const data = await response.json();
      if(data["hydra:member"] === undefined){
        return { success: true, data: data };
      }
      this.taxeRates = data["hydra:member"];
      this.globalStore.isAuthenticated = true;
      return { success: true, data: this.taxeRates };
    } else {
      return {
        success: false,
        error: `Error with status: ${response.status}`,
        status: response.status,
      };
    }
  }

  async getTaxeRate() {
    try {
      const response = await fetch(ApiURL.taxeRates, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }
}

export default TaxeRateService;

