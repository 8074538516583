import React from "react";
import {
  FormControl,
  NativeSelect,
  InputLabel,
  Typography,
} from "@mui/material";

interface StatusSelectProps {
  currentStatus: string;
  handleChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  value: string;
  getStatusTranslation: (status: string) => string;
}

const StatusSelect: React.FC<StatusSelectProps> = ({
  currentStatus,
  handleChange,
  getStatusTranslation,
  value,
}) => {
  // Fonction qui détermine les options disponibles en fonction du statut actuel
  const getStatusOptions = () => {
    switch (currentStatus) {
      case "DRAFT":
        return ["DRAFT", "PUBLISHED", "CANCELLED"];
      case "PUBLISHED":
        return ["PUBLISHED", "CANCELLED"];
      default:
        return [currentStatus];
    }
  };

  const statusOptions = getStatusOptions();

  return (
    <FormControl fullWidth>
      {currentStatus !== "CANCELLED" ? (
        <>
          <InputLabel variant="standard">Modifier le statut</InputLabel>
          <NativeSelect value={value} onChange={handleChange} name="statut">
            {getStatusOptions().map((status) => (
              <option key={status} value={status}>
                {getStatusTranslation(status)}{" "}
                {/* Affichage de la traduction */}
              </option>
            ))}
          </NativeSelect>
        </>
      ) : (
        <Typography variant="body1" color="error">
          Cette facture est annulée et ne peut plus être modifiée.
        </Typography>
      )}
    </FormControl>
  );
};

export default StatusSelect;
