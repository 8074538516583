import React, { useEffect, useState } from "react";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ThemeModal } from "../../../components/modal/Modal";
import { Box, Button } from "@mui/material";
import { CreateViewInvoice } from "./createViewInvoice";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../theme";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import { toast } from "react-toastify";
import { GeneratePdf, PreviewInvoice } from "./GeneratePdf.jsx";

export function PreviewInvoiceModal({ open, handleClose, selectedInvoice }) {
  const theme = useTheme();
  const colors = theme.palette ? tokens(theme.palette.mode) : null;
  const store = useGlobalStore();
  const [invoiceData, setInvoiceData] = useState(null);

  useEffect(() => {
    const fetchInvoice = async () => {
      try {
        const result = await store.invoice.getInvoiceById(selectedInvoice.id);
        console.log(result, "result");
        if (result.success) {
          setInvoiceData(result.data);
          console.log(result.data, "regarde ici");
        } else {
          toast.error(
            "Une erreur est survenue lors de la récupération de la facture."
          );
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de la facture :", error);
      }
    };

    if (open && selectedInvoice !== null && selectedInvoice !== undefined) {
      fetchInvoice(); // Appeler la fonction pour récupérer la facture
    }
  }, [open, selectedInvoice, store.invoice]);

  const MyDocument = () => {
    if (!invoiceData) {
      return null; // ou un composant de chargement si nécessaire
    }

    return <GeneratePdf invoiceData={invoiceData} />;
  };

  // Détermine le nom de fichier en fonction du numéro de facture (invoiceData.number)
  const getFileName = () => {
    return invoiceData && invoiceData.number
      ? `Facture_${invoiceData.number}.pdf`
      : "Facture.pdf"; // Nom par défaut si le numéro est absent
  };

  return (
    <ThemeModal open={open} onClose={handleClose}>
      <Box className="modal-content">
        <Box
          sx={{
            background: colors.grey[400],
            display: "flex",
            justifyContent: "flex-start",
            padding: "16px",
          }}
          className="modal-header"
        >
          <PDFDownloadLink document={<MyDocument />} fileName={getFileName()}>
            {({ loading }) => (
              <Button
                disabled={loading} // Désactive le bouton pendant le chargement
                style={{ color: colors.grey[100] }}
                className="modal-close"
              >
                Télécharger PDF
              </Button>
            )}
          </PDFDownloadLink>
        </Box>
        <Box className="modal-scroll">
          <Box className="">
            <CreateViewInvoice invoiceData={invoiceData} />
          </Box>
        </Box>
      </Box>
    </ThemeModal>
  );
}
