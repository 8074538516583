import { Box, Modal } from "@mui/material";
import "./Modal.css";

export function ThemeModal({ open, onClose, children }) {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>{children}</Box>
    </Modal>
  );
}
