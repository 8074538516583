import React, { useEffect, useState } from "react";
import {
  Box,
  IconButton,
  Typography,
  Tabs,
  Tab,
  CircularProgress,
  useTheme,
} from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import { tokens } from "../../theme";
import { mockTransactions } from "../../store/data/mockdata";
import Header from "../../components/header/Header";
import StatBox from "../../components/statBox/StatBox";
import EmailIcon from "@mui/icons-material/Email";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TrafficIcon from "@mui/icons-material/Traffic";
import { useGlobalStore } from "../../store/globalStore.tsx";
import LineChart from "../../components/linceChart/LinceChart";

const IndexPage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [customersPerMonth, setCustomersPerMonth] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  const [payments, setPayments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const store = useGlobalStore();

  useEffect(() => {
    const fetchCustomersPerMonth = async () => {
      const response = await store.customer.getCustomersPerMonth();
      if (response.success) {
        setCustomersPerMonth(response.data);
      } else {
        console.error(
          "Erreur lors de la récupération des clients :",
          response.error
        );
      }
    };

    const fetchPayments = async () => {
      const response = await store.invoice.getPayments();
      console.log("Réponse de la récupération des paiements :", response.data);
      if (response.success) {
        const paymentsData = response.data.map((payment) => {
          return {
            id: payment.id,
            paymentStatus: payment.paymentStatus || "IN_PROGRESS",
            amountTotal: payment.totalWithTax || 0,
            companyName: payment.customer.companyName || "Nom non disponible",
         //   dueDate: payment.payment.installmentSchedules[0]?.dueDate || null,
          };
        });
        setPayments(paymentsData);
        console.log("Paiements récupérés:", paymentsData);
      } else {
        console.error(
          "Erreur lors de la récupération des paiements :",
          response.error
        );
      }
    };

    const loadData = async () => {
      await fetchCustomersPerMonth();
      await fetchPayments();
      setIsLoading(false); 
    };

    loadData();
  }, []);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "long", year: "numeric" };
    return new Date(dateString).toLocaleDateString("fr-FR", options);
  };

  const now = new Date();
  const thirtyDaysAgo = new Date();
  thirtyDaysAgo.setDate(now.getDate() - 30); 
  console.log("Date actuelle :", now);
  console.log("Date d'il y a 30 jours :", thirtyDaysAgo);

  const latePaymentsMoreThan30Days = payments.filter((payment) => {
    if (payment.paymentStatus === "LATE") {
      const dueDate = new Date(payment.dueDate);

      console.log(
        `Paiement ID: ${payment.id}, Statut: ${payment.paymentStatus}`
      );
      console.log("Date d'échéance :", dueDate);


      const isLateMoreThan30Days = dueDate < thirtyDaysAgo;
      console.log(
        `Est en retard de plus de 30 jours ? ${isLateMoreThan30Days}`
      );

      return isLateMoreThan30Days; // Paiements en retard de plus de 30 jours
    }
    return false;
  });

  const latePaymentsLessThan30Days = payments.filter((payment) => {
    if (payment.paymentStatus === "LATE") {
      const dueDate = new Date(payment.dueDate);
      const isLateLessThan30Days = dueDate >= thirtyDaysAgo && dueDate < now;
      console.log(
        `Est en retard de moins de 30 jours ? ${isLateLessThan30Days}`
      );
      return isLateLessThan30Days; // Paiements en retard de moins de 30 jours
    }
    return false;
  });

  if (isLoading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress size={50} />
      </Box>
    );
  }

  return (
    <Box m="20px">
      {/* HEADER */}
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header
          title="DASHBOARD"
          subtitle="Les chiffres clés de votre entreprise"
        />
      </Box>

      {/* GRID & CHARTS */}
      <Box
        display="grid"
        gridTemplateColumns="repeat(12, 1fr)"
        gridAutoRows="140px"
        gap="20px"
      >
        {/* ROW 1 */}
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="12,361"
            subtitle="Demandes client"
            progress="0.75"
            increase="+14%"
            icon={
              <EmailIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="431,225"
            subtitle="Chiffre d'affaire mensuel"
            progress="0.50"
            increase="+21%"
            icon={
              <PointOfSaleIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title={customersPerMonth.length.toString()}
            subtitle="Nouveaux clients ce mois-ci"
            progress={Math.min(customersPerMonth.length / 100, 1).toFixed(2)}
            increase="+5%"
            icon={
              <PersonAddIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>
        <Box
          gridColumn="span 3"
          backgroundColor={colors.primary[400]}
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <StatBox
            title="1,325,134"
            subtitle="Traffic Received"
            progress="0.80"
            increase="+43%"
            icon={
              <TrafficIcon
                sx={{ color: colors.greenAccent[600], fontSize: "26px" }}
              />
            }
          />
        </Box>

        {/* ROW 2: Revenu */}
        <Box
          gridColumn="span 8"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
        >
          {/* Section Revenu complète, avec graphique et bouton de téléchargement */}
          <Box
            mt="25px"
            p="0 30px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box>
              <Typography
                variant="h5"
                fontWeight="600"
                color={colors.grey[100]}
              >
                Revenu
              </Typography>
              <Typography
                variant="h3"
                fontWeight="bold"
                color={colors.greenAccent[500]}
              >
                59,342.32 €
              </Typography>
            </Box>
            <Box>
              <IconButton>
                <DownloadOutlinedIcon
                  sx={{ fontSize: "26px", color: colors.greenAccent[500] }}
                />
              </IconButton>
            </Box>
          </Box>
          <Box height="250px" m="-20px 0 0 0">
            <LineChart isDashboard={true} />
          </Box>
        </Box>

        {/* Tabs Section for Paiement Récent */}
        <Box
          gridColumn="span 4"
          gridRow="span 2"
          backgroundColor={colors.primary[400]}
          overflow="auto"
        >
          {/* Tabs */}
          <Tabs
            value={selectedTab}
            onChange={handleTabChange}
            textColor="inherit"
            variant="fullWidth"
          >
            <Tab label="Derniers paiements" />
            <Tab label="Retard < 30 jours" sx={{ fontSize: "12px" }} />
            <Tab label="Retard > 30 jours" />
          </Tabs>

          {selectedTab === 0 ? ( // Derniers paiements
            <>
              {payments.filter((payment) => payment.paymentStatus === "PAID")
                .length > 0 ? (
                <Box p="15px">
                  <Typography variant="h6" color={colors.grey[100]} mb="10px">
                    Derniers Paiements :
                  </Typography>
                  {payments
                    .filter((payment) => payment.paymentStatus === "PAID")
                    .map((payment) => (
                      <Box
                        key={payment.id}
                        display="flex"
                        justifyContent="space-between"
                        p="10px"
                        bgcolor={colors.primary[500]}
                        borderRadius="4px"
                        mb="5px"
                      >
                        <Typography color={colors.grey[100]}>
                          Client : {payment.companyName}
                        </Typography>
                        <Typography color={colors.greenAccent[500]}>
                          Montant : {payment.amountTotal} €
                        </Typography>
                      </Box>
                    ))}
                </Box>
              ) : (
                <Typography p="15px" color={colors.grey[100]}>
                  Aucun paiement payé pour l'instant.
                </Typography>
              )}
            </>
          ) : selectedTab === 1 ? ( // Retard < 30 jours
            <>
              {latePaymentsLessThan30Days.length > 0 ? (
                <Box p="15px">
                  <Typography variant="h6" color={colors.grey[100]} mb="10px">
                    Factures en retard depuis moins de 30 jours :
                  </Typography>
                  {latePaymentsLessThan30Days.map((payment) => (
                    <Box
                      key={payment.id}
                      display="flex"
                      justifyContent="space-between"
                      p="10px"
                      bgcolor={colors.primary[500]}
                      borderRadius="4px"
                      mb="5px"
                    >
                      <Typography color={colors.grey[100]}>
                        Client : {payment.companyName}
                      </Typography>
                      <Typography color={colors.greenAccent[500]}>
                        Date :{" "}
                        {payment.dueDate
                          ? formatDate(payment.dueDate)
                          : "Date non disponible"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography p="15px" color={colors.grey[100]}>
                  Aucun paiement en retard de moins de 30 jours.
                </Typography>
              )}
            </>
          ) : (
            // Retard > 30 jours
            <>
              {latePaymentsMoreThan30Days.length > 0 ? (
                <Box p="15px">
                  <Typography variant="h6" color={colors.grey[100]} mb="10px">
                    Factures en retard depuis plus de 30 jours :
                  </Typography>
                  {latePaymentsMoreThan30Days.map((payment) => (
                    <Box
                      key={payment.id}
                      display="flex"
                      justifyContent="space-between"
                      p="10px"
                      bgcolor={colors.primary[500]}
                      borderRadius="4px"
                      mb="5px"
                    >
                      <Typography color={colors.grey[100]}>
                        Client : {payment.companyName}
                      </Typography>
                      <Typography color={colors.greenAccent[500]}>
                        Date :{" "}
                        {payment.dueDate
                          ? formatDate(payment.dueDate)
                          : "Date non disponible"}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : (
                <Typography p="15px" color={colors.grey[100]}>
                  Aucun paiement en retard de plus de 30 jours.
                </Typography>
              )}
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default IndexPage;