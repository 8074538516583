const countries = [
    { label: "France", value: "France" },
    { label: "Afghanistan", value: "Afghanistan" },
    { label: "Afrique du Sud", value: "Afrique du Sud" },
    { label: "Albanie", value: "Albanie" },
    { label: "Algérie", value: "Algérie" },
    { label: "Allemagne", value: "Allemagne" },
    { label: "Andorre", value: "Andorre" },
    { label: "Angola", value: "Angola" },
    { label: "Antigua-et-Barbuda", value: "Antigua-et-Barbuda" },
    { label: "Arabie saoudite", value: "Arabie saoudite" },
    { label: "Argentine", value: "Argentine" },
    { label: "Arménie", value: "Arménie" },
    { label: "Australie", value: "Australie" },
    { label: "Autriche", value: "Autriche" },
    { label: "Azerbaïdjan", value: "Azerbaïdjan" },
    { label: "Bahamas", value: "Bahamas" },
    { label: "Bahreïn", value: "Bahreïn" },
    { label: "Bangladesh", value: "Bangladesh" },
    { label: "Barbade", value: "Barbade" },
    { label: "Belgique", value: "Belgique" },
    { label: "Belize", value: "Belize" },
    { label: "Bénin", value: "Bénin" },
    { label: "Bhoutan", value: "Bhoutan" },
    { label: "Biélorussie", value: "Biélorussie" },
    { label: "Birmanie", value: "Birmanie" },
    { label: "Bolivie", value: "Bolivie" },
    { label: "Bosnie-Herzégovine", value: "Bosnie-Herzégovine" },
    { label: "Botswana", value: "Botswana" },
    { label: "Brésil", value: "Brésil" },
    { label: "Brunei", value: "Brunei" },
    { label: "Bulgarie", value: "Bulgarie" },
    { label: "Burkina Faso", value: "Burkina Faso" },
    { label: "Burundi", value: "Burundi" },
    { label: "Cambodge", value: "Cambodge" },
    { label: "Cameroun", value: "Cameroun" },
    { label: "Canada", value: "Canada" },
    { label: "Cap-Vert", value: "Cap-Vert" },
    { label: "Chili", value: "Chili" },
    { label: "Chine", value: "Chine" },
    { label: "Chypre", value: "Chypre" },
    { label: "Colombie", value: "Colombie" },
    { label: "Comores", value: "Comores" },
    { label: "Congo", value: "Congo" },
    { label: "Corée du Nord", value: "Corée du Nord" },
    { label: "Corée du Sud", value: "Corée du Sud" },
    { label: "Costa Rica", value: "Costa Rica" },
    { label: "Côte d'Ivoire", value: "Côte d'Ivoire" },
    { label: "Croatie", value: "Croatie" },
    { label: "Cuba", value: "Cuba" },
    { label: "Danemark", value: "Danemark" },
    { label: "Djibouti", value: "Djibouti" },
    { label: "Dominique", value: "Dominique" },
    { label: "Égypte", value: "Égypte" },
    { label: "Émirats arabes unis", value: "Émirats arabes unis" },
    { label: "Équateur", value: "Équateur" },
    { label: "Érythrée", value: "Érythrée" },
    { label: "Espagne", value: "Espagne" },
    { label: "Estonie", value: "Estonie" },
    { label: "États-Unis", value: "États-Unis" },
    { label: "Eswatini", value: "Eswatini" },
    { label: "Éthiopie", value: "Éthiopie" },
    { label: "Fidji", value: "Fidji" },
    { label: "Finlande", value: "Finlande" },
    { label: "Gabon", value: "Gabon" },
    { label: "Gambie", value: "Gambie" },
    { label: "Géorgie", value: "Géorgie" },
    { label: "Ghana", value: "Ghana" },
    { label: "Grèce", value: "Grèce" },
    { label: "Grenade", value: "Grenade" },
    { label: "Guatemala", value: "Guatemala" },
    { label: "Guinée", value: "Guinée" },
    { label: "Guinée-Bissau", value: "Guinée-Bissau" },
    { label: "Guyana", value: "Guyana" },
    { label: "Haïti", value: "Haïti" },
    { label: "Honduras", value: "Honduras" },
    { label: "Hongrie", value: "Hongrie" },
    { label: "Inde", value: "Inde" },
    { label: "Indonésie", value: "Indonésie" },
    { label: "Irak", value: "Irak" },
    { label: "Iran", value: "Iran" },
    { label: "Irlande", value: "Irlande" },
    { label: "Islande", value: "Islande" },
    { label: "Israël", value: "Israël" },
    { label: "Italie", value: "Italie" },
    { label: "Jamaïque", value: "Jamaïque" },
    { label: "Japon", value: "Japon" },
    { label: "Jordanie", value: "Jordanie" },
    { label: "Kazakhstan", value: "Kazakhstan" },
    { label: "Kenya", value: "Kenya" },
    { label: "Kirghizistan", value: "Kirghizistan" },
    { label: "Kiribati", value: "Kiribati" },
    { label: "Koweït", value: "Koweït" },
    { label: "Laos", value: "Laos" },
    { label: "Lesotho", value: "Lesotho" },
    { label: "Lettonie", value: "Lettonie" },
    { label: "Liban", value: "Liban" },
    { label: "Libéria", value: "Libéria" },
    { label: "Libye", value: "Libye" },
    { label: "Liechtenstein", value: "Liechtenstein" },
    { label: "Lituanie", value: "Lituanie" },
    { label: "Luxembourg", value: "Luxembourg" },
    { label: "Madagascar", value: "Madagascar" },
    { label: "Malaisie", value: "Malaisie" },
    { label: "Malawi", value: "Malawi" },
    { label: "Maldives", value: "Maldives" },
    { label: "Mali", value: "Mali" },
    { label: "Malte", value: "Malte" },
    { label: "Maroc", value: "Maroc" },
    { label: "Maurice", value: "Maurice" },
    { label: "Mauritanie", value: "Mauritanie" },
    { label: "Mexique", value: "Mexique" },
    { label: "Micronésie", value: "Micronésie" },
    { label: "Moldavie", value: "Moldavie" },
    { label: "Monaco", value: "Monaco" },
    { label: "Mongolie", value: "Mongolie" },
    { label: "Monténégro", value: "Monténégro" },
    { label: "Mozambique", value: "Mozambique" },
    { label: "Namibie", value: "Namibie" },
    { label: "Nauru", value: "Nauru" },
    { label: "Népal", value: "Népal" },
    { label: "Nicaragua", value: "Nicaragua" },
    { label: "Niger", value: "Niger" },
    { label: "Nigéria", value: "Nigéria" },
    { label: "Norvège", value: "Norvège" },
    { label: "Nouvelle-Zélande", value: "Nouvelle-Zélande" },
    { label: "Oman", value: "Oman" },
    { label: "Ouganda", value: "Ouganda" },
    { label: "Ouzbékistan", value: "Ouzbékistan" },
    { label: "Pakistan", value: "Pakistan" },
    { label: "Palaos", value: "Palaos" },
    { label: "Panama", value: "Panama" },
    { label: "Papouasie-Nouvelle-Guinée", value: "Papouasie-Nouvelle-Guinée" },
    { label: "Paraguay", value: "Paraguay" },
    { label: "Pays-Bas", value: "Pays-Bas" },
    { label: "Pérou", value: "Pérou" },
    { label: "Philippines", value: "Philippines" },
    { label: "Pologne", value: "Pologne" },
    { label: "Portugal", value: "Portugal" },
    { label: "Qatar", value: "Qatar" },
    { label: "République centrafricaine", value: "République centrafricaine" },
    { label: "République dominicaine", value: "République dominicaine" },
    { label: "République tchèque", value: "République tchèque" },
    { label: "Roumanie", value: "Roumanie" },
    { label: "Royaume-Uni", value: "Royaume-Uni" },
    { label: "Russie", value: "Russie" },
    { label: "Rwanda", value: "Rwanda" },
    { label: "Saint-Christophe-et-Niévès", value: "Saint-Christophe-et-Niévès" },
    { label: "Sainte-Lucie", value: "Sainte-Lucie" },
    { label: "Saint-Marin", value: "Saint-Marin" },
    { label: "Saint-Vincent-et-les-Grenadines", value: "Saint-Vincent-et-les-Grenadines" },
    { label: "Salomon", value: "Salomon" },
    { label: "Salvador", value: "Salvador" },
    { label: "Samoa", value: "Samoa" },
    { label: "São Tomé-et-Principe", value: "São Tomé-et-Principe" },
    { label: "Sénégal", value: "Sénégal" },
    { label: "Serbie", value: "Serbie" },
    { label: "Seychelles", value: "Seychelles" },
    { label: "Sierra Leone", value: "Sierra Leone" },
    { label: "Singapour", value: "Singapour" },
    { label: "Slovaquie", value: "Slovaquie" },
    { label: "Slovénie", value: "Slovénie" },
    { label: "Somalie", value: "Somalie" },
    { label: "Soudan", value: "Soudan" },
    { label: "Soudan du Sud", value: "Soudan du Sud" },
    { label: "Sri Lanka", value: "Sri Lanka" },
    { label: "Suède", value: "Suède" },
    { label: "Suisse", value: "Suisse" },
    { label: "Suriname", value: "Suriname" },
    { label: "Syrie", value: "Syrie" },
    { label: "Tadjikistan", value: "Tadjikistan" },
    { label: "Tanzanie", value: "Tanzanie" },
    { label: "Tchad", value: "Tchad" },
    { label: "Thaïlande", value: "Thaïlande" },
    { label: "Timor oriental", value: "Timor oriental" },
    { label: "Togo", value: "Togo" },
    { label: "Tonga", value: "Tonga" },
    { label: "Trinité-et-Tobago", value: "Trinité-et-Tobago" },
    { label: "Tunisie", value: "Tunisie" },
    { label: "Turkménistan", value: "Turkménistan" },
    { label: "Turquie", value: "Turquie" },
    { label: "Tuvalu", value: "Tuvalu" },
    { label: "Ukraine", value: "Ukraine" },
    { label: "Uruguay", value: "Uruguay" },
    { label: "Vanuatu", value: "Vanuatu" },
    { label: "Vatican", value: "Vatican" },
    { label: "Venezuela", value: "Venezuela" },
    { label: "Vietnam", value: "Vietnam" },
    { label: "Yémen", value: "Yémen" },
    { label: "Zambie", value: "Zambie" },
    { label: "Zimbabwe", value: "Zimbabwe" },
  ];
  
  export const getCountries = () => countries;
  
  export const getDefaultCountry = () => "France";
  
  