import React from 'react';

const CustomInput = ({
  type = 'text',
  name,
  placeholder = 'Entrez une valeur',
  value,
  onChange,
  onBlur,
  errorMessage,
}) => {
  return (
    <div>
      <input
        type={type}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        className={`custom-pdf-input ${errorMessage ? 'border-red-500' : 'border-gray-300'}`}
      />

      {errorMessage && (
        <p className="text-red-500 text-sm mt-1">{errorMessage}</p>
      )}
    </div>
  );
};

export default CustomInput;