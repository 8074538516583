import React from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  CircularProgress,
} from "@mui/material";
import logo_cevep from "../../../img/logo/logo_cevep.webp";
import "./previewInvoice.css";

export function CreateViewInvoice(invoiceDataObject) {
  const invoiceData = invoiceDataObject.invoiceData;

  // Fonction pour formater les nombres en notation française
  function formatNumber(value) {
    return value
      .toFixed(2)
      .replace(".", ",")
      .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  }

  if (!invoiceData) {
    return (
      <div>
        <CircularProgress />
      </div>
    );
  }

  const taxRates = {};
  let totalAmount = 0;

  if (invoiceData && invoiceData.invoiceLines) {
    invoiceData.invoiceLines.forEach((line) => {
      const amountHT = (line.price / 100) * line.quantity;
      const amountTVA = amountHT * (line.taxRate.amount / 100);
      const amountTTC = amountHT + amountTVA;
      totalAmount += amountTTC;

      if (!taxRates[line.taxRate.amount]) {
        taxRates[line.taxRate.amount] = {
          value: line.taxRate.amount,
          totalAmountTVA: 0,
        };
      }
      taxRates[line.taxRate.amount].totalAmountTVA += amountTVA;
    });
  }

  const downPayment = invoiceData.payment?.DepositAmount
    ? invoiceData.payment.DepositAmount / 100
    : 0;
  const remainingAmount = totalAmount - downPayment;

  return (
    <Box className="preview-invoice">
      {/* Logo */}
      <Box display="flex" justifyContent="center" alignItems="center" marginBottom="20px">
        <img src={logo_cevep} alt="Logo" style={{ width: "300px" }} />
      </Box>

      {/* Header Section */}
      <Box display="flex" flexDirection="row" marginBottom="40px">
        <Box width="50%" display="flex" flexDirection="column" alignItems="center">
          <Typography fontWeight={700}>16 F rue des Jardins</Typography>
          <Typography fontWeight={700}>95480 PIERRELAYE</Typography>
          <Typography fontWeight={700}>Tél. : 01.34.32.53.53</Typography>
          <Typography fontSize={8} color="#33b540" fontWeight={700}>
            E. Mail. : pubccvep@gmail.com
          </Typography>
          <Typography
            sx={{ marginTop: "20px", fontSize: "20px", fontWeight: "700" }}
          >
            Facture {invoiceData.number}
          </Typography>
          <Typography marginBottom="20px">
            Pierrelaye, le{" "}
            {new Date(invoiceData.issuedAt).toLocaleDateString("fr-FR")}
          </Typography>
        </Box>
        <Box width="50%" display="flex" flexDirection="column" alignItems="center">
          {invoiceData.customer.companyName && (
            <Typography>Client: {invoiceData.customer.companyName}</Typography>
          )}
          {invoiceData.customer.numberSiret && (
            <Typography>N°SIRET: {invoiceData.customer.numberSiret}</Typography>
          )}
          {invoiceData.customer.numberTva && (
            <Typography>N°TVA: {invoiceData.customer.numberTva}</Typography>
          )}
        </Box>
      </Box>

      {/* Table for Invoice Lines */}
      <TableContainer component={Paper} marginBottom="20px">
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "60%" }}>DESIGNATION</TableCell>
              <TableCell>QTE</TableCell>
              <TableCell>PRIX UNITAIRE (EUROS)</TableCell>
              <TableCell>MONTANT H.T (EUROS)</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invoiceData.invoiceLines.map((line, index) => (
              <TableRow key={index}>
                <TableCell>{line.description}</TableCell>
                <TableCell>{line.quantity}</TableCell>
                <TableCell>{formatNumber(line.price / 100)} €</TableCell>
                <TableCell>
                  {formatNumber((line.price / 100) * line.quantity)} €
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Table for Totals */}
      <TableContainer sx={{marginTop: "40px"}} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={{ width: "80%" }}>Description</TableCell>
              <TableCell>Montant</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.entries(taxRates).map(([rate, values]) => (
              <TableRow key={rate}>
                <TableCell>Taux de TVA {values.value}%</TableCell>
                <TableCell>{formatNumber(values.totalAmountTVA)} €</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell>
                <Typography fontWeight={700}>Montant total</Typography>
              </TableCell>
              <TableCell>
                <Typography fontWeight={700}>
                  {formatNumber(totalAmount)} €
                </Typography>
              </TableCell>
            </TableRow>
            {downPayment > 0 && (
              <>
                <TableRow>
                  <TableCell>Acompte versé</TableCell>
                  <TableCell>{formatNumber(downPayment)} €</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Montant restant à payer</TableCell>
                  <TableCell>{formatNumber(remainingAmount)} €</TableCell>
                </TableRow>
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Footer */}
      <Box
        style={{
          position: "absolute",
          bottom: "40px",
          width: "100%",
          textAlign: "center",
          fontWeight: 700,
          color: "#33b540",
        }}
      >
        Siège social: Pierrelaye (Val d'Oise) S.A.R.L. au Capital de 8000€. RCS:
        353 688 591 00067 Code APE: 7311Z
      </Box>
    </Box>
  );
}
