import React, { useEffect, useState, useCallback } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import { Grid, Box, Button, MenuItem, Select, Typography, useTheme, CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import UpdateIcon from '@mui/icons-material/Update';
import Dashboard from "../../dashboard/DashboardIndex.tsx";
import { useGlobalStore } from "../../../store/globalStore.tsx";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import { tokens } from "../../../theme";
import { Table } from "../../../components/table/Table";
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import SaveIcon from '@mui/icons-material/Save';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import frLocale from "@fullcalendar/core/locales/fr";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

const EditProduct = () => {
  const { id } = useParams();
  const location = useLocation();
  const store = useGlobalStore();
  const [productData, setProductData] = useState(null);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [historyDetails, setHistoryDetails] = useState({});
  const [locationHistory, setLocationHistory] = useState({});
  const [customers, setCustomers] = useState([]);

  const [formValues, setFormValues] = useState({
    product: {
      ref: "",
      user: "",
    },
    localisation: {
      address: "",
      city: "",
      postalCode: "",
      latitude: "",
      longitude: "",
      visible: "",
      direction: "",
    },
  });

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [loadingHistories, setLoadingHistories] = useState({});

  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [editingHistory, setEditingHistory] = useState(null);

  const [isSaving, setIsSaving] = useState(false);

  const [tabValue, setTabValue] = useState({});

  const [currentEvents, setCurrentEvents] = useState([]);

  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [newHistory, setNewHistory] = useState({
    customerId: "",
    startDate: "",
    endDate: "",
    locationId: ""
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSavingHistory, setIsSavingHistory] = useState(false);
  const [isUpdatingHistory, setIsUpdatingHistory] = useState(false);

  const [existingEvent, setExistingEvent] = useState(null);

  const [showAddForm, setShowAddForm] = useState({});

  const navigate = useNavigate();

  const [generalTabValue, setGeneralTabValue] = useState(0);

  const [showGpsModal, setShowGpsModal] = useState(false);
  const [hasGpsCoordinates, setHasGpsCoordinates] = useState(false);

  const [isSavingGps, setIsSavingGps] = useState(false);

  const handleGeneralTabChange = (event, newValue) => {
    setGeneralTabValue(newValue);
  };

  const handleTabChange = useCallback((locationId) => (event, newValue) => {
    setTabValue(prev => ({
      ...prev,
      [locationId]: newValue
    }));
  }, []);

  const TabPanel = ({ children, value, index }) => {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`tabpanel-${index}`}
        aria-labelledby={`tab-${index}`}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  };

  useEffect(() => {
    const fetchProductData = async () => {
      setIsLoading(true);
      try {
        if (id) {
          const result = await store.product.getProductsById(id);
          console.log("Raw product data:", result);
          
          if (result.success) {
            const data = result.data;
            
            console.log("ProductLocalisation:", data.productLocalisation);
            console.log("Latitude:", data.productLocalisation?.latitude);
            console.log("Longitude:", data.productLocalisation?.longitude);
            
            const hasCoordinates = 
              data.productLocalisation?.latitude !== null && 
              data.productLocalisation?.latitude !== undefined &&
              data.productLocalisation?.longitude !== null && 
              data.productLocalisation?.longitude !== undefined;
            
            setHasGpsCoordinates(hasCoordinates);
            
            const normalizedData = {
              ...data,
              advertisingLocations: Array.isArray(data.AdvertisingLocation) 
                ? data.AdvertisingLocation.map(location => ({
                    ...location,
                    historyAdvertisingLocations: Array.isArray(location.historyAdvertisingLocations)
                      ? location.historyAdvertisingLocations
                      : []
                  }))
                : []
            };
            
            console.log("Normalized data:", normalizedData);
            setProductData(normalizedData);
  
            if (data.productLocalisation) {
              setFormValues(prev => ({
                ...prev,
                localisation: {
                  address: data.productLocalisation.address || '',
                  city: data.productLocalisation.city || '',
                  postalCode: data.productLocalisation.postalCode || '',
                  latitude: data.productLocalisation.latitude || '',
                  longitude: data.productLocalisation.longitude || '',
                  visible: data.productLocalisation.visible || '',
                  direction: data.productLocalisation.direction || '',
                },
                product: {
                  ref: data.ref || '',
                  user: data.user?.id || '',
                  name: data.name || '',
                }
              }));
            }
          } else {
            console.error("Erreur dans la réponse:", result.error);
            toast.error("Erreur lors du chargement du produit");
          }
        }
  
        const usersResult = await store.product.getUsers();
        if (usersResult.success) {
          setUsers(usersResult.data);
        }
  
        const customerResult = await store.product.getCustomers();
        if (customerResult.success) {
          setCustomers(customerResult.data);
        }
  
      } catch (error) {
        console.error("Erreur détaillée:", error);
        toast.error("Erreur lors de la récupération des données");
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProductData();
  }, [id, store]);

  useEffect(() => {
    if (productData?.advertisingLocations) {
      const events = productData.advertisingLocations.flatMap(location => {
        return (location.HistoryAdvertisingLocations || [])
          .map(history => {
            const historyId = history['@id']?.split('/').pop();
            const historyDetail = historyDetails[historyId];
            
            if (historyDetail) {
              return {
                id: historyDetail.id,
                locationId: location.id,
                title: historyDetail.customerCompanyName || 'Client non défini',
                start: historyDetail.startDate,
                end: historyDetail.endDate,
                backgroundColor: generateRandomColor(historyDetail.customerCompanyName),
                borderColor: generateRandomColor(historyDetail.customerCompanyName),
                display: 'block'
              };
            }
            return null;
          })
          .filter(event => event !== null);
      });

      setCurrentEvents(events);
    }
  }, [productData, historyDetails]);

  // Ajouter cette fonction pour gnérer des couleurs cohérentes
  const generateRandomColor = (companyName) => {
    if (!companyName) return '#808080';
    let hash = 0;
    for (let i = 0; i < companyName.length; i++) {
      hash = companyName.charCodeAt(i) + ((hash << 5) - hash);
    }
    const hue = hash % 360;
    return `hsl(${hue}, 70%, 50%)`;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    if (['ref', 'user', 'name', 'direction'].includes(name)) {
      setFormValues((prevValues) => ({
        ...prevValues,
        product: {
          ...prevValues.product,
          [name]: value,
        },
      }));
    } else {
      setFormValues((prevValues) => ({
        ...prevValues,
        localisation: {
          ...prevValues.localisation,
          [name]: value,
        },
      }));
    }
  };

  const handleCreate = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    try {
      console.log("Données du produit avant l'envoi :", formValues);
    
      const productData = {
        ref: formValues.product.ref,
        name: formValues.product.name || null,
        user: formValues.product.user || null, 
        address: formValues.localisation.address,
        city: formValues.localisation.city,
        postalCode: formValues.localisation.postalCode,
        visible: formValues.localisation.visible,
        direction: formValues.localisation.direction || null,
        latitude: parseFloat(formValues.localisation.latitude),
        longitude: parseFloat(formValues.localisation.longitude),
    };
  
      const result = await store.product.postProduct(productData);
      console.log("Résultat complet:", result);

      if (result.success && result.data && result.data.data) {
        const productId = result.data.data.productId;
        console.log("ID extrait:", productId);

        if (typeof productId === 'number' || typeof productId === 'string') {
          toast.success("Produit créé avec succès !");
          navigate(`/product/edit/${productId}`);
        } else {
          console.error("Format d'ID invalide:", productId);
          toast.error("Erreur lors de la redirection");
        }
      } else {
        console.error("Erreur dans le formulaire", result.error || "Aucune erreur spécifiée");
        toast.error("Erreur dans le formulaire", result.error || "Aucune erreur spécifiée");
      }
    } catch (error) {
      console.error("Erreur lors de la création du produit :", error);
      toast.error("Erreur lors de la création du produit :", error);
    } finally {
      setIsSubmitting(false);
    }
  };
  
  
const handleUpdate = async (e) => {
  e.preventDefault();
  setIsUpdating(true);
  try {
    e.preventDefault();

    // Créez un objet pour les données mises à jour, incluant tous les champs nécessaires
    const updatedProductData = {
      ref: formValues.product.ref,
      name: formValues.product.name || null,
      user: formValues.product.user ? `/api/users/${formValues.product.user}` : null,
      address: formValues.localisation.address, 
      city: formValues.localisation.city, 
      postalCode: formValues.localisation.postalCode, 
      latitude: parseFloat(formValues.localisation.latitude), 
      longitude: parseFloat(formValues.localisation.longitude), 
      visible: formValues.localisation.visible, 
      direction: formValues.localisation.direction || null,
    };

    console.log("Updated product data:", updatedProductData);
    const result = await store.product.putProduct(id, updatedProductData);
    if (result.success) {
      console.log("Produit mis à jour avec succès !");
      toast.success("Produit mis à jour avec succès !");
    } else {
      console.error("Erreur dans la mise à jour", result.error || "Aucune erreur spécifiée");
      toast.error(`Erreur dans la mise à jour : ${result.error || "Aucune erreur spécifiée"}`);
    }
  } catch (error) {
    console.error("Erreur lors de la mise à jour du produit :", error);
    toast.error(`Erreur lors de la mise à jour : ${error.message}`);
  } finally {
    setIsUpdating(false);
  }
};

  // Ajoutez cette fonction utilitaire
  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  // Fonction pour ajouter une nouvelle ligne vide
  const handleAddNewDateLine = (locationId) => {
    setShowAddForm(prev => ({
      ...prev,
      [locationId]: true
    }));
  };

  // Ajoutez cette fonction dans votre composant
  const handleCreateLocation = async () => {
    if (!id) return; 
    
    const result = await store.product.createAdvertisingLocation(id);
    
    if (result.success) {
      toast.success("Emplacement crée avec succès");
      
      // Mettre  jour directement productData avec le nouvel emplacement
      const productResult = await store.product.getProductsById(id);
      if (productResult.success) {
        const normalizedData = {
          ...productResult.data,
          advertisingLocations: Array.isArray(productResult.data.AdvertisingLocation) 
            ? productResult.data.AdvertisingLocation.map(location => ({
                ...location,
                historyAdvertisingLocations: Array.isArray(location.historyAdvertisingLocations)
                  ? location.historyAdvertisingLocations
                  : []
              }))
            : []
        };
        setProductData(normalizedData);
      }
    } else {
      toast.error(result.error || "Erreur lors de la création de l'emplacement");
    }
  };

  const handleSaveHistory = async (locationId) => {
    const newHistoryLines = locationHistory[locationId] || [];
    
    try {
      for (const line of newHistoryLines) {
        if (!line.customerId || !line.startDate || !line.endDate) {
          toast.error("Veuillez remplir tous les champs");
          return;
        }

        const historyData = {
          id: locationId,
          customerId: `/api/customers/${line.customerId}`,
          advertisingLocation: `/api/advertising_locations/${locationId}`,
          startDate: line.startDate,
          endDate: line.endDate
        };

        const result = await store.product.checkAdvertisingLocationAvailability(historyData);
        
        if (result.success && result.data.isAvailable) {
          // Récupérer les détails de l'emplacement après l'ajout
          const locationDetails = await store.product.getAdvertisingLocationDetails(locationId);
          if (locationDetails.success) {
            const historyArray = locationDetails.data.historyAdvertisingLocations || [];
            
            // Mettre à jour les détails de l'historique
            const details = {};
            await Promise.all(
              historyArray.map(async (history) => {
                const result = await store.product.getHistoryDetails(history.id);
                if (result.success) {
                  details[history.id] = {
                    ...result.data,
                    customerCompanyName: result.data.customerCompanyName || 'Non défini',
                    locationId: locationId // Ajouter l'ID de l'emplacement
                  };
                }
              })
            );

            // Mettre à jour historyDetails
            setHistoryDetails(prev => ({
              ...prev,
              ...details
            }));

            // Mettre à jour productData
            const productResult = await store.product.getProductsById(id);
            if (productResult.success) {
              const normalizedData = {
                ...productResult.data,
                advertisingLocations: Array.isArray(productResult.data.AdvertisingLocation) 
                  ? productResult.data.AdvertisingLocation.map(loc => ({
                      ...loc,
                      historyAdvertisingLocations: loc.id === locationId 
                        ? historyArray 
                        : (loc.historyAdvertisingLocations || [])
                    }))
                  : []
              };
              setProductData(normalizedData);
            }

            // Vider l'historique local pour cet emplacement
            setLocationHistory(prev => ({
              ...prev,
              [locationId]: []
            }));

            toast.success("Historique ajouté avec succès");
          }
        } else {
          toast.error(result.data?.message || "Erreur lors de l'enregistrement");
        }
      }
    } catch (error) {
      console.error("Erreur lors de l'enregistrement de l'historique:", error);
      toast.error("Erreur lors de l'enregistrement de l'historique");
    }
  };

  const handleOpenEditModal = (historyData, locationId) => {
    // Trouver le client correspondant à partir du nom de l'entreprise
    const customer = customers.find(c => c.companyName === historyData.customerCompanyName);
    
    setEditingHistory({
      id: historyData.id,
      locationId: locationId,
      customerId: customer?.id || "", // Utiliser l'ID du client trouvé
      startDate: historyData.startDate ? new Date(historyData.startDate).toISOString().split('T')[0] : "",
      endDate: historyData.endDate ? new Date(historyData.endDate).toISOString().split('T')[0] : ""
    });
    setIsEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setEditingHistory(null);
  };

  const handleUpdateHistory = async () => {
    setIsUpdatingHistory(true);
    try {
      if (!editingHistory.customerId || !editingHistory.startDate || !editingHistory.endDate) {
        toast.error("Veuillez remplir tous les champs");
        return;
      }

      const historyData = {
        id: editingHistory.id,
        customerId: `/api/customers/${editingHistory.customerId}`,
        advertisingLocation: `/api/advertising_locations/${editingHistory.locationId}`,
        startDate: editingHistory.startDate,
        endDate: editingHistory.endDate
      };

      const result = await store.product.updateHistoryAdvertisingLocation(historyData);
      
      if (result.success) {
        toast.success("L'historique a été modifié avec succès");
        handleCloseEditModal();
        
        // Mettre à jour les détails immédiatement
        const locationDetails = await store.product.getAdvertisingLocationDetails(editingHistory.locationId);
        if (locationDetails.success) {
          const historyArray = locationDetails.data.historyAdvertisingLocations || [];
          
          const details = {};
          await Promise.all(
            historyArray.map(async (history) => {
              if (!history.id) return;
              const result = await store.product.getHistoryDetails(history.id);
              if (result.success) {
                details[history.id] = {
                  ...result.data,
                  customerCompanyName: result.data.customerCompanyName || 'Non défini'
                };
              }
            })
          );
          
          setHistoryDetails(prev => ({ ...prev, ...details }));
          
          // Mettre à jour productData
          setProductData(prev => ({
            ...prev,
            advertisingLocations: prev.advertisingLocations.map(loc => 
              loc.id === editingHistory.locationId 
                ? { ...loc, historyAdvertisingLocations: historyArray }
                : loc
            )
          }));
        }
      } else {
        toast.error(result.error || "Erreur lors de la modification");
      }
    } catch (error) {
      console.error("Erreur lors de la modification de l'historique:", error);
      toast.error("Erreur lors de la modification de l'historique");
    } finally {
      setIsUpdatingHistory(false);
    }
  };

  const historyColumns = [
    {
      field: "customerCompanyName",
      headerName: "CLIENT",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "startDate",
      headerName: "DATE DE DÉBUT",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => formatDate(params.row.startDate),
    },
    {
      field: "endDate",
      headerName: "DATE DE FIN",
      headerAlign: "center",
      align: "center",
      flex: 1,
      cellClassName: "name-column--cell",
      renderCell: (params) => formatDate(params.row.endDate),
    },
    {
      field: "Modifier dates",
      headerName: "MODIFIER DATES",
      headerAlign: "center",
      align: "center",
      cellClassName: "name-column--cell",
      renderCell: (params) => (
        <Button 
          variant="contained" 
          color="secondary"
          onClick={() => handleOpenEditModal(params.row, params.row.locationId)}
        >
          <CreateIcon /> 
        </Button>
      ),
    }
  ];

  const handleSave = async (locationId) => {
    setIsSaving(true);
    try {
      if (showAddForm[locationId] && newHistory.customerId && newHistory.startDate && newHistory.endDate) {
        // Si le formulaire est visible et rempli, sauvegarder le nouvel historique
        await handleSaveNewHistory();
      } else {
        // Sinon, sauvegarder les modifications existantes
        await handleSaveHistory(locationId);
      }
    } catch (error) {
      console.error("Erreur lors de la sauvegarde:", error);
      toast.error("Erreur lors de l'enregistrement des dates");
    } finally {
      setIsSaving(false);
    }
  };

  const handleDateSelect = (selectInfo, locationId) => {
    const endDate = new Date(selectInfo.endStr);
    endDate.setDate(endDate.getDate() - 1);
    
    const foundEvent = currentEvents.find(event => {
      const eventStart = new Date(event.start);
      const eventEnd = new Date(event.end);
      const selectedStart = new Date(selectInfo.startStr);
      const selectedEnd = endDate;
      
      return (
        event.locationId === locationId &&
        ((selectedStart >= eventStart && selectedStart <= eventEnd) ||
         (selectedEnd >= eventStart && selectedEnd <= eventEnd))
      );
    });

    setExistingEvent(foundEvent);

    if (foundEvent) {
      const customer = customers.find(c => c.companyName === foundEvent.title);
      setNewHistory({
        customerId: customer?.id || "",
        startDate: selectInfo.startStr,
        endDate: endDate.toISOString().split('T')[0],
        locationId: locationId
      });
    } else {
      setNewHistory({
        customerId: "",
        startDate: selectInfo.startStr,
        endDate: endDate.toISOString().split('T')[0],
        locationId: locationId
      });
    }
    
    setIsAddModalOpen(true);
  };

  const handleCloseAddModal = () => {
    setIsAddModalOpen(false);
    setExistingEvent(null);
    setNewHistory({
      customerId: "",
      startDate: "",
      endDate: "",
      locationId: ""
    });
  };

  const handleSaveNewHistory = async () => {
    setIsSavingHistory(true);
    try {
      if (!newHistory.customerId || !newHistory.startDate || !newHistory.endDate || !newHistory.locationId) {
        toast.error("Veuillez remplir tous les champs");
        return;
      }

      const historyData = {
        id: newHistory.locationId,
        customerId: `/api/customers/${newHistory.customerId}`,
        advertisingLocation: `/api/advertising_locations/${newHistory.locationId}`,
        startDate: newHistory.startDate,
        endDate: newHistory.endDate
      };

      const result = await store.product.checkAdvertisingLocationAvailability(historyData);
      
      if (result.success && result.data.isAvailable) {
        // Récupérer les détails de l'emplacement après l'ajout
        const locationDetails = await store.product.getAdvertisingLocationDetails(newHistory.locationId);
        if (locationDetails.success) {
          const historyArray = locationDetails.data.historyAdvertisingLocations || [];
          
          // Récupérer les détails du nouvel historique
          const details = {};
          await Promise.all(
            historyArray.map(async (history) => {
              if (!history.id) return;
              const result = await store.product.getHistoryDetails(history.id);
              if (result.success) {
                details[history.id] = {
                  ...result.data,
                  customerCompanyName: result.data.customerCompanyName || 'Non défini',
                  locationId: newHistory.locationId // Ajouter l'ID de l'emplacement
                };
              }
            })
          );

          // Mettre à jour historyDetails
          setHistoryDetails(prev => ({
            ...prev,
            ...details
          }));

          // Mettre à jour productData
          const productResult = await store.product.getProductsById(id);
          if (productResult.success) {
            const normalizedData = {
              ...productResult.data,
              advertisingLocations: Array.isArray(productResult.data.AdvertisingLocation) 
                ? productResult.data.AdvertisingLocation.map(loc => ({
                    ...loc,
                    historyAdvertisingLocations: loc.id === newHistory.locationId 
                      ? historyArray 
                      : (loc.historyAdvertisingLocations || [])
                  }))
                : []
            };
            setProductData(normalizedData);
          }

          // Mettre à jour currentEvents
          const selectedCustomer = customers.find(c => c.id === parseInt(newHistory.customerId));
          const newEvent = {
            id: Object.keys(details)[0], // ID du nouvel historique
            locationId: newHistory.locationId,
            title: selectedCustomer?.companyName || 'Client non défini',
            start: newHistory.startDate,
            end: newHistory.endDate,
            backgroundColor: generateRandomColor(selectedCustomer?.companyName),
            borderColor: generateRandomColor(selectedCustomer?.companyName),
            display: 'block'
          };

          setCurrentEvents(prev => [...prev, newEvent]);

          handleCloseAddModal();
          toast.success("Historique ajouté avec succès");
        }
      } else {
        toast.error(result.data?.message || "Cette période n'est pas disponible");
      }
    } catch (error) {
      console.error("Erreur lors de l'ajout de l'historique:", error);
      toast.error("Erreur lors de l'ajout de l'historique");
    } finally {
      setIsSavingHistory(false);
    }

    setShowAddForm(prev => ({
      ...prev,
      [newHistory.locationId]: false
    }));
  };

  const handleEventClick = (selected) => {
    if (
      window.confirm(
        `Etes-vous sûr de vouloir supprimer cet événement '${selected.event.title}'`
      )
    ) {
      selected.event.remove();
    }
  };

  const handleDelete = async (event, productId) => {
    event.stopPropagation();
    if (window.confirm('Êtes-vous sûr de vouloir supprimer ce produit ?')) {
      try {
        const result = await store.product.softDeleteProduct(productId);
        if (result.success) {
          toast.success('Produit supprimé avec succès');
          navigate('/product');
        } else {
          toast.error(result.error || 'Erreur lors de la suppression');
        }
      } catch (error) {
        console.error('Erreur:', error);
        toast.error('Erreur lors de la suppression');
      }
    }
  };

  // Ajouter un état pour suivre le chargement des données
  const [isHistoryLoading, setIsHistoryLoading] = useState(true);

  // Ajoutez cette fonction pour gérer la mise à jour des coordonnées GPS
  const handleGpsUpdate = () => {
    setIsSavingGps(true);
    try {
      // Vérifier si les valeurs sont valides
      const latitude = parseFloat(formValues.localisation.latitude);
      const longitude = parseFloat(formValues.localisation.longitude);

      if (isNaN(latitude) || isNaN(longitude)) {
        toast.error("Veuillez entrer des coordonnées GPS valides");
        return;
      }

      // Mettre à jour le formulaire avec les nouvelles coordonnées
      setFormValues(prev => ({
        ...prev,
        localisation: {
          ...prev.localisation,
          latitude: latitude,
          longitude: longitude
        }
      }));

      // Fermer le modal
      setShowGpsModal(false);
      toast.success("Coordonnées GPS ajoutées au formulaire");
    } catch (error) {
      console.error("Erreur lors de l'ajout des coordonnées GPS:", error);
      toast.error("Erreur lors de l'ajout des coordonnées GPS");
    } finally {
      setIsSavingGps(false);
    }
  };

  return (
    <>
      <Dashboard
        title={"Produit"}
        subtitle={id ? "Modifier un produit" : "Créer un produit"}
        element={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button 
              variant="contained" 
              color="secondary"
              onClick={() => navigate('/product')}
            >
              <AddIcon />
              Retour
            </Button>
            {id && ( // N'afficher le bouton supprimer que si on est en mode édition
              <Button
                variant="contained"
                color="error"
                onClick={(event) => handleDelete(event, id)}
              >
                <DeleteIcon />
                Supprimer
              </Button>
            )}
          </Box>
        }
      />

      <Box sx={{ maxWidth: "1380px", margin: "auto", padding: "20px" }}>
        <Box m="20px">
          <Grid container spacing={2} p={2} sx={{ display: "flex", justifyContent: "center" }}>
            <form onSubmit={id ? handleUpdate : handleCreate}>
              <Grid container spacing={2}>
                {/* Product Fields */}
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Référence du produit"
                    name="ref"
                    value={formValues.product.ref}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <Select
                    fullWidth
                    variant="filled"
                    displayEmpty
                    name="user"
                    value={formValues.product.user}
                    onChange={handleChange}
                  >
                    <MenuItem value="">Choisir un commercial</MenuItem>
                    {users.map((user) => (
                      <MenuItem key={user.id} value={user.id}>
                        {user.firstName} {user.lastName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>

                {/* Localisation Fields */}
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Adresse"
                    name="address"
                    value={formValues.localisation.address}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Ville"
                    name="city"
                    value={formValues.localisation.city}
                    onChange={handleChange}
                  />
                </Grid>                
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Code postal"
                    name="postalCode"
                    value={formValues.localisation.postalCode}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Visible"
                    name="visible"
                    type="text"
                    value={formValues.localisation.visible}
                    onChange={handleChange}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    fullWidth
                    variant="filled"
                    label="Nom du produit"
                    name="name"
                    type="text"
                    value={formValues.product.name}
                    onChange={handleChange}
                    InputLabelProps={{ 
                      shrink: true,
                      sx: { transform: 'translate(12px, 7px) scale(0.75)' }
                    }}
                  />
                </Grid>
                {!hasGpsCoordinates && (
                  <Grid item xs={12} lg={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="secondary"
                      onClick={() => setShowGpsModal(true)}
                      sx={{ 
                        padding: "15px",
                      }}
                    >
                      <AddIcon sx={{ mr: 1 }} />
                      Ajout de coordonnées GPS
                    </Button>
                  </Grid>
                )}
              </Grid>
              <Box>
                <Button
                  sx={{ mt: "20px" }}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  disabled={id ? isUpdating : isSubmitting}
                  startIcon={
                    (id ? isUpdating : isSubmitting) ? 
                    <CircularProgress size={20} color="inherit" /> : 
                    <SaveIcon />
                  }
                >
                  {id ? 
                    (isUpdating ? "Mise à jour..." : "Mettre à jour") : 
                    (isSubmitting ? "Création..." : "Créer")
                  }
                </Button>
              </Box>
            </form>
          </Grid>
        </Box>

        <Box m="20px">
          {/* Ajout du titre et du bouton */}
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'space-between', 
              alignItems: 'center',
              mb: 2,
              m: 1
            }}
          >
            <Typography 
              variant="h6" 
            >
              Historique des emplacements
            </Typography>
            <Button 
              variant="contained" 
              color="secondary"
              onClick={handleCreateLocation}
            >
              <AddIcon />
              Créer un emplacement
            </Button>
          </Box>
          
          {/* Accordion "Tous les historiques" */}
          <Accordion
            sx={{mb: 2, mt: 2}}
            onChange={async (event, expanded) => {
              if (expanded && productData?.advertisingLocations) {
                setIsHistoryLoading(true);
                if (Object.keys(historyDetails).length === 0) {
                  try {
                    await Promise.all(
                      productData.advertisingLocations.map(async (location) => {
                        const locationDetails = await store.product.getAdvertisingLocationDetails(location.id);
                        if (locationDetails.success) {
                          const historyArray = locationDetails.data.historyAdvertisingLocations || [];
                          
                          const details = {};
                          await Promise.all(
                            historyArray.map(async (history) => {
                              if (!history.id) return;
                              const result = await store.product.getHistoryDetails(history.id);
                              if (result.success) {
                                details[history.id] = {
                                  ...result.data,
                                  customerCompanyName: result.data.customerCompanyName || 'Non défini',
                                  locationId: location.id
                                };
                              }
                            })
                          );
                          
                          setHistoryDetails(prev => ({ ...prev, ...details }));
                        }
                      })
                    );
                  } catch (error) {
                    console.error("Erreur lors de la récupération des détails:", error);
                    toast.error("Erreur lors du chargement des détails");
                  } finally {
                    setIsHistoryLoading(false);
                  }
                } else {
                  setIsHistoryLoading(false);
                }
              }
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel-all-history-content"
              id="panel-all-history-header"
            >
              <Typography variant="h6">
                Tous les historiques
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              {isHistoryLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                  <CircularProgress />
                </Box>
              ) : Object.keys(historyDetails).length > 0 ? (
                <>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center", mb: 2 }}>
                    <Tabs value={generalTabValue} onChange={handleGeneralTabChange}>
                      <Tab 
                        label="Tableau" 
                        sx={{ 
                          color: 'secondary.main',
                          '&.Mui-selected': { color: 'secondary.main' }
                        }} 
                      />
                      <Tab 
                        label="Calendrier" 
                        sx={{ 
                          color: 'secondary.main',
                          '&.Mui-selected': { color: 'secondary.main' }
                        }} 
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={generalTabValue} index={0}>
                    <Box sx={{ mt: 2 }}>
                      {isHistoryLoading ? (
                        <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                          <CircularProgress />
                        </Box>
                      ) : (
                        <Table 
                          Data={Object.values(historyDetails)
                            .map(detail => ({
                              id: detail.id,
                              locationId: detail.locationId,
                              customerCompanyName: detail.customerCompanyName || 'Non défini',
                              startDate: detail.startDate,
                              endDate: detail.endDate,
                              emplacementNumber: productData?.advertisingLocations?.findIndex(loc => loc.id === detail.locationId) + 1
                            }))} 
                          columns={[
                            ...historyColumns,
                            {
                              field: "emplacementNumber",
                              headerName: "EMPLACEMENT",
                              headerAlign: "center",
                              align: "center",
                              flex: 1,
                              cellClassName: "name-column--cell",
                              renderCell: (params) => `Emplacement ${params.row.emplacementNumber}`,
                            }
                          ]} 
                        />
                      )}
                    </Box>
                  </TabPanel>

                  <TabPanel value={generalTabValue} index={1}>
                    <Box display="flex" justifyContent="space-between">
                      {/* CALENDAR SIDEBAR */}
                      <Box
                        flex="1 1 20%"
                        backgroundColor={colors.primary[400]}
                        p="15px"
                        borderRadius="4px"
                      >
                        <Typography variant="h5">vénements</Typography>
                        <List>
                          {currentEvents
                            .sort((a, b) => {
                              const dateA = new Date(a.start).getTime();
                              const dateB = new Date(b.start).getTime();
                              return dateA - dateB;
                            })
                            .map((event) => (
                              <ListItem
                                key={event.id}
                                sx={{
                                  backgroundColor: event.backgroundColor || colors.greenAccent[500],
                                  margin: "10px 0",
                                  borderRadius: "2px",
                                  cursor: 'pointer',
                                  '&:hover': {
                                    opacity: 0.9,
                                  },
                                }}
                              >
                                <ListItemText
                                  primary={
                                    <Box>
                                      <Typography variant="subtitle1">
                                        {event.title}
                                      </Typography>
                                      <Typography variant="caption">
                                        Emplacement {productData?.advertisingLocations?.findIndex(loc => loc.id === event.locationId) + 1}
                                      </Typography>
                                    </Box>
                                  }
                                  secondary={
                                    <Box>
                                      <Typography>
                                        Début: {event.start ? new Date(event.start).toLocaleDateString('fr-FR') : ''}
                                      </Typography>
                                      <Typography>
                                        Fin: {event.end ? new Date(event.end).toLocaleDateString('fr-FR') : ''}
                                      </Typography>
                                    </Box>
                                  }
                                />
                              </ListItem>
                            ))}
                        </List>
                      </Box>

                      {/* CALENDAR */}
                      <Box flex="1 1 100%" ml="15px">
                        <FullCalendar
                          height="75vh"
                          plugins={[
                            dayGridPlugin,
                            timeGridPlugin,
                            interactionPlugin,
                            listPlugin,
                          ]}
                          headerToolbar={{
                            left: "prev,next today",
                            center: "title",
                            right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                          }}
                          initialView="dayGridMonth"
                          editable={false}
                          selectable={false}
                          selectMirror={true}
                          dayMaxEvents={true}
                          events={currentEvents}
                          locales={[frLocale]}
                          locale="fr"
                          eventContent={(arg) => ({
                            html: `
                              <div style="color: white;">
                                ${arg.event.title}
                                <br/>
                                <small>Emplacement ${productData?.advertisingLocations?.findIndex(loc => loc.id === arg.event.extendedProps.locationId) + 1}</small>
                              </div>
                            `
                          })}
                          dayHeaderContent={(arg) => (
                            <span style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                              {arg.text}
                            </span>
                          )}
                          dayCellContent={(arg) => (
                            <span style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                              {arg.dayNumberText}
                            </span>
                          )}
                        />
                      </Box>
                    </Box>
                  </TabPanel>
                </>
              ) : (
                <Typography 
                  variant="h6" 
                  sx={{ 
                    textAlign: 'center',
                    color: colors.grey[100],
                    padding: '2rem'
                  }}
                >
                  Aucun historique disponible pour ce produit
                </Typography>
              )}
            </AccordionDetails>
          </Accordion>

          {/* Section des accordéons des emplacements */}
          {productData?.advertisingLocations && productData.advertisingLocations.length > 0 && (
            <Box>
              {productData.advertisingLocations.map((location, index) => (
                <Accordion 
                  key={location.id} 
                  sx={{ mb: 2 }}
                  onChange={async (event, expanded) => {
                    if (expanded) {
                      try {
                        setLoadingHistories(prev => ({ ...prev, [location.id]: true }));
                        
                        const locationHistories = Object.values(historyDetails).filter(
                          detail => detail.locationId === location.id
                        );

                        if (locationHistories.length === 0) {
                          const locationDetails = await store.product.getAdvertisingLocationDetails(location.id);
                          
                          if (locationDetails.success) {
                            const historyArray = locationDetails.data.historyAdvertisingLocations || [];
                            
                            const details = {};
                            await Promise.all(
                              historyArray.map(async (history) => {
                                if (!history.id) return;
                                const result = await store.product.getHistoryDetails(history.id);
                                if (result.success) {
                                  details[history.id] = {
                                    ...result.data,
                                    customerCompanyName: result.data.customerCompanyName || 'Non défini',
                                    locationId: location.id
                                  };
                                }
                              })
                            );
                            
                            setHistoryDetails(prev => ({ ...prev, ...details }));
                          }
                        }
                      } catch (error) {
                        console.error("Erreur lors de la récupération des détails:", error);
                        toast.error("Erreur lors du chargement des détails");
                      } finally {
                        setLoadingHistories(prev => ({ ...prev, [location.id]: false }));
                      }
                    }
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>
                      <Typography>Emplacement {formValues.product.name} {index + 1}</Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    {loadingHistories[location.id] ? (
                      <Box sx={{ display: 'flex', justifyContent: 'center', p: 2 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider', display: "flex", justifyContent: "center"}}>
                          <Tabs 
                            value={tabValue[location.id] || 0}
                            onChange={handleTabChange(location.id)}
                            aria-label="location tabs"
                            textColor="secondary"
                            indicatorColor="secondary"
                          >
                            <Tab 
                              label="Tableau" 
                              sx={{ 
                                color: 'secondary.main',
                                '&.Mui-selected': { color: 'secondary.main' }
                              }} 
                            />
                            <Tab 
                              label="Calendrier" 
                              sx={{ 
                                color: 'secondary.main',
                                '&.Mui-selected': { color: 'secondary.main' }
                              }} 
                            />
                          </Tabs>
                        </Box>

                        <TabPanel value={tabValue[location.id] || 0} index={0}>
                          {/* Tableau existant */}
                          {location.HistoryAdvertisingLocations?.length > 0 && (
                            <Box sx={{ height: 'auto', width: '100%', minHeight: '200px' }}>
                              <Table 
                                Data={Object.values(historyDetails)
                                  .filter(detail => {
                                    const historyId = detail?.id?.toString();
                                    return location.HistoryAdvertisingLocations.some(
                                      history => history['@id']?.split('/').pop() === historyId
                                    );
                                  })
                                  .map(detail => ({
                                    id: detail.id,
                                    locationId: location.id,
                                    customerCompanyName: detail.customerCompanyName || 'Non défini',
                                    startDate: detail.startDate,
                                    endDate: detail.endDate
                                  }))} 
                                columns={historyColumns} 
                              />
                            </Box>
                          )}

                          {/* Message si pas d'historique */}
                          {!location.HistoryAdvertisingLocations?.length && (
                            <Typography 
                              sx={{ 
                                color: colors.grey[100],
                                textAlign: 'center',
                                padding: '1rem'
                              }}
                            >
                              Aucun historique disponible pour cet emplacement
                            </Typography>
                          )}

                          {/* Nouveau formulaire d'ajout */}
                          {showAddForm[location.id] && (
                            <Box sx={{ mt: 2 }}>
                              <Box sx={{ display: 'flex', gap: 2, alignItems: 'flex-start' }}>
                                <FormControl sx={{ minWidth: 200 }}>
                                  <InputLabel>Client</InputLabel>
                                  <Select
                                    value={newHistory.customerId}
                                    onChange={(e) => setNewHistory(prev => ({ 
                                      ...prev, 
                                      customerId: e.target.value,
                                      locationId: location.id 
                                    }))}
                                  >
                                    <MenuItem value="">Sélectionner un client</MenuItem>
                                    {customers.map((customer) => (
                                      <MenuItem key={customer.id} value={customer.id}>
                                        {customer.companyName}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                                <TextField
                                  label="Date de début"
                                  type="date"
                                  InputLabelProps={{ shrink: true }}
                                  value={newHistory.startDate}
                                  onChange={(e) => setNewHistory(prev => ({ 
                                    ...prev, 
                                    startDate: e.target.value 
                                  }))}
                                />

                                <TextField
                                  label="Date de fin"
                                  type="date"
                                  InputLabelProps={{ shrink: true }}
                                  value={newHistory.endDate}
                                  onChange={(e) => setNewHistory(prev => ({ 
                                    ...prev, 
                                    endDate: e.target.value 
                                  }))}
                                />
                              </Box>
                            </Box>
                          )}
                        </TabPanel>

                        <TabPanel value={tabValue[location.id] || 0} index={1}>
                          <Box display="flex" justifyContent="space-between">
                            {/* CALENDAR SIDEBAR */}
                            <Box
                              flex="1 1 20%"
                              backgroundColor={colors.primary[400]}
                              p="15px"
                              borderRadius="4px"
                            >
                              <Typography variant="h5">Événements</Typography>
                              <List>
                                {currentEvents
                                  .filter(event => event.locationId === location.id)
                                  .sort((a, b) => {
                                    const dateA = new Date(a.start).getTime();
                                    const dateB = new Date(b.start).getTime();
                                    return dateA - dateB;
                                  })
                                  .map((event) => (
                                    <ListItem
                                      key={event.id}
                                      sx={{
                                        backgroundColor: event.backgroundColor || colors.greenAccent[500],
                                        margin: "10px 0",
                                        borderRadius: "2px",
                                        cursor: 'pointer',
                                        '&:hover': {
                                          opacity: 0.9,
                                        },
                                      }}
                                      onClick={() => handleOpenEditModal(
                                        {
                                          id: event.id,
                                          customerCompanyName: event.title,
                                          startDate: event.start,
                                          endDate: event.end
                                        },
                                        event.locationId
                                      )}
                                    >
                                      <ListItemText
                                        primary={event.title}
                                        secondary={
                                          <Box>
                                            <Typography>
                                              Début: {event.start ? new Date(event.start).toLocaleDateString('fr-FR') : ''}
                                            </Typography>
                                            <Typography>
                                              Fin: {event.end ? new Date(event.end).toLocaleDateString('fr-FR') : ''}
                                            </Typography>
                                          </Box>
                                        }
                                      />
                                    </ListItem>
                                  ))}
                              </List>
                            </Box>

                            {/* CALENDAR */}
                            <Box flex="1 1 100%" ml="15px">
                              <FullCalendar
                                height="75vh"
                                plugins={[
                                  dayGridPlugin,
                                  timeGridPlugin,
                                  interactionPlugin,
                                  listPlugin,
                                ]}
                                headerToolbar={{
                                  left: "prev,next today",
                                  center: "title",
                                  right: "dayGridMonth,timeGridWeek,timeGridDay,listMonth",
                                }}
                                initialView="dayGridMonth"
                                editable={true}
                                selectable={true}
                                selectMirror={true}
                                dayMaxEvents={true}
                                select={(selectInfo) => handleDateSelect(selectInfo, location.id)}
                                eventClick={handleEventClick}
                                events={currentEvents.filter(event => event.locationId === location.id)}
                                locales={[frLocale]}
                                locale="fr"
                                eventContent={(arg) => ({
                                  html: `<div style="color: white;">${arg.event.title}</div>`
                                })}
                                dayHeaderContent={(arg) => (
                                  <span style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                                    {arg.text}
                                  </span>
                                )}
                                dayCellContent={(arg) => (
                                  <span style={{ color: theme.palette.mode === 'dark' ? 'white' : 'black' }}>
                                    {arg.dayNumberText}
                                  </span>
                                )}
                              />
                            </Box>
                          </Box>
                        </TabPanel>
                      </>
                    )}
                  </AccordionDetails>
                  <TabPanel value={tabValue[location.id] || 0} index={0}>
                    {/* Boutons existants */}
                    <Button 
                      variant="contained" 
                      color="secondary" 
                      sx={{mb: 2, ml: 2, display: "flex", float: "left"}}
                      onClick={() => handleAddNewDateLine(location.id)}
                    >
                      <UpdateIcon sx={{ mr: 1 }} />
                      Ajouter des dates
                    </Button>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 2, mb: 2 }}>
                      {showAddForm[location.id] && (
                        <Button
                          variant="outlined"
                          color="secondary"
                          onClick={() => {
                            setShowAddForm(prev => ({
                              ...prev,
                              [location.id]: false
                            }));
                            setNewHistory({
                              customerId: "",
                              startDate: "",
                              endDate: "",
                              locationId: ""
                            });
                          }}
                        >
                          Annuler
                        </Button>
                      )}
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleSave(location.id)}
                        disabled={isSaving}
                        startIcon={isSaving ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                        sx={{mr: 2}}
                      >
                        {isSaving ? "Ajout en cours..." : "Enregistrer"}
                      </Button>
                    </Box>
                  </TabPanel>
                </Accordion>
              ))}
            </Box>
          )}
        </Box>
      </Box>

      <Dialog open={isEditModalOpen} onClose={handleCloseEditModal}>
        <DialogTitle>Modifier les dates</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, minWidth: '400px' }}>
            <FormControl fullWidth>
              <InputLabel>Client</InputLabel>
              <Select
                value={editingHistory?.customerId || ""}
                onChange={(e) => setEditingHistory(prev => ({ ...prev, customerId: e.target.value }))}
              >
                <MenuItem value="">Sélectionner un client</MenuItem>
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Date de début"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={editingHistory?.startDate || ""}
              onChange={(e) => setEditingHistory(prev => ({ ...prev, startDate: e.target.value }))}
            />

            <TextField
              label="Date de fin"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={editingHistory?.endDate || ""}
              onChange={(e) => setEditingHistory(prev => ({ ...prev, endDate: e.target.value }))}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEditModal} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleUpdateHistory} color="secondary" variant="contained" disabled={isUpdatingHistory} startIcon={isUpdatingHistory ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}>
            {isUpdatingHistory ? "Mise à jour..." : "Enregistrer"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={isAddModalOpen} onClose={handleCloseAddModal}>
        <DialogTitle>
          Ajouter un nouvel historique
          {newHistory.customerId && existingEvent && (
            <Typography variant="subtitle2" color="secondary">
              Un client est déjà présent sur ces dates
            </Typography>
          )}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, minWidth: '400px' }}>
            <FormControl fullWidth>
              <InputLabel>Client</InputLabel>
              <Select
                value={newHistory.customerId}
                onChange={(e) => setNewHistory(prev => ({ ...prev, customerId: e.target.value }))}
              >
                <MenuItem value="">Sélectionner un client</MenuItem>
                {customers.map((customer) => (
                  <MenuItem key={customer.id} value={customer.id}>
                    {customer.companyName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <TextField
              label="Date de début"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={newHistory.startDate}
              onChange={(e) => setNewHistory(prev => ({ ...prev, startDate: e.target.value }))}
            />

            <TextField
              label="Date de fin"
              type="date"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={newHistory.endDate}
              onChange={(e) => setNewHistory(prev => ({ ...prev, endDate: e.target.value }))}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAddModal} color="secondary">
            Annuler
          </Button>
          <Button onClick={handleSaveNewHistory} color="secondary" variant="contained" disabled={isSavingHistory} startIcon={isSavingHistory ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}>
            {isSavingHistory ? "Enregistrement..." : "Enregistrer"}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog 
        open={showGpsModal && !hasGpsCoordinates}
        onClose={() => setShowGpsModal(false)}
      >
        <DialogTitle>Ajouter les coordonnées GPS</DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2, minWidth: '400px' }}>
            <TextField
              label="Latitude"
              type="number"
              fullWidth
              value={formValues.localisation.latitude}
              onChange={(e) => handleChange({
                target: { name: 'latitude', value: e.target.value }
              })}
            />
            <TextField
              label="Longitude"
              type="number"
              fullWidth
              value={formValues.localisation.longitude}
              onChange={(e) => handleChange({
                target: { name: 'longitude', value: e.target.value }
              })}
            />
            <TextField
              label="Direction"
              type="text"
              fullWidth
              value={formValues.localisation.direction}
              onChange={(e) => setFormValues(prev => ({
                ...prev,
                localisation: {
                  ...prev.localisation,
                  direction: e.target.value
                }
              }))}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowGpsModal(false)} color="secondary">
            Annuler
          </Button>
          <Button 
            onClick={handleGpsUpdate} 
            color="secondary" 
            variant="contained"
            disabled={isSavingGps}
            startIcon={isSavingGps ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
          >
            {isSavingGps ? "Validation..." : "Valider"}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditProduct;