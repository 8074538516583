export class ApiService {
  static async request(url: string, options: RequestInit): Promise<Response> {
    try {
      const response = await fetch(url, options);
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(`Error: ${response.status} - ${errorData.detail}`);
      }
      return response;
    } catch (error: any) {
      throw new Error(`Network error: ${error.message}`);
    }
  }
}
