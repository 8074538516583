import React from "react";
import { makeAutoObservable } from "mobx";
import { useContext, useRef } from "react";
import { createContext } from "react";
import AuthService from "./auth/auth.tsx";
import CustomerService from "./data/customer/customer.tsx";
import UserService from "./data/user/user.tsx";
import InvoiceService from "./data/invoice/invoice.tsx";
import ProductService from "./data/product/product.tsx";
import TaxeRateService from "./data/taxeRate/taxeRate.tsx";

export default class GlobalStore {
  authService: AuthService;
  customer: CustomerService;
  userService: UserService;
  invoice: InvoiceService;
  taxeRate: TaxeRateService;
  product: ProductService;
  isAuthenticated: boolean = false;  

  // Auth

  constructor() {
    makeAutoObservable(this);
    this.authService = new AuthService(this);
    this.customer = new CustomerService(this);
    this.userService = new UserService(this);
    this.invoice = new InvoiceService(this);
    this.taxeRate = new TaxeRateService(this);
    this.product = new ProductService(this);
  }

  navigate = null;

  setNavigate(navigateFunction) {
    this.navigate = navigateFunction;
  }
}

const GlobalStoreContext = createContext<GlobalStore | undefined>(undefined);

export const useGlobalStore = (): GlobalStore => {
  const context = useContext(GlobalStoreContext);
  if (!context) {
    throw new Error("useGlobalStore must be used within a GlobalStoreProvider");
  }
  return context;
};

export function GlobalStoreProvider({ children }) {
  const store = useRef(new GlobalStore());
  return (
    <GlobalStoreContext.Provider value={store.current}>
      {children}
    </GlobalStoreContext.Provider>
  );
}
