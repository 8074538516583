import { Formik } from "formik";
import * as Yup from "yup";
import { ThemeModal } from "../../components/modal/Modal";
import { formatPhoneNumber, cleanPhoneNumber } from "../../utils/phoneUtils";
import { getCountries, getDefaultCountry } from "../../utils/country";
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import { textFieldStyleModal } from "../../components/style/componentStyle";
import { useGlobalStore } from "../../store/globalStore.tsx";
import { useTheme } from "@emotion/react";
import { tokens } from "../../theme";
import { useState } from "react";
import { toast } from "react-toastify";

// Initial values
const initialValues = {
  customerType: "professionnel",
  companyName: "",
  numberSiret: "",
  numberTva: "",
  contactTitle: "",
  contactLastName: "",
  contactFirstName: "",
  contactEmail: "",
  officeNumber: "",
  address: "",
  postalCode: "",
  city: "",
  country: "",
  paymentDaysCondition: 0,
};

// Validation regex
const phoneRegExp = /^(\d{2} ){4}\d{2}$|^\d{10}$/; // Accepte les numéros avec ou sans espaces
const postalCodeRegExp = /^\d{5}$/;
const siretRegExp = /^\d{14}$/;

// Validation schema
const checkoutSchema = Yup.object({
  customerType: Yup.string().required("Type de client requis."),
  companyName: Yup.string().required("Le nom de l'entreprise est requis."),
  contactEmail: Yup.string(),
  contactFirstName: Yup.string(),
  contactLastName: Yup.string(),
  contactTitle: Yup.string(),
  country: Yup.string(),
  numberSiret: Yup.string().matches(
    siretRegExp,
    "Le numéro SIRET doit contenir 14 chiffres."
  ),
  numberTva: Yup.string(),
  officeNumber: Yup.string()
    .matches(phoneRegExp, "Numéro de téléphone invalide."),
  paymentDaysCondition: Yup.string().required(
    "Les conditions de paiement sont requises."
  ),
  postalCode: Yup.string().matches(postalCodeRegExp, "Code postal invalide."),
  city: Yup.string(),
  address: Yup.string(),
});

export function CustomerModal({ open, setOpen, fetchCustomers }) {
  const store = useGlobalStore();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const handleClose = () => setOpen(false);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const sanitizedValues = {
        ...values,
        officeNumber: cleanPhoneNumber(values.officeNumber),
      };

      const result = await store.customer.postCustomer(sanitizedValues);
      if (result.success) {
        toast.success("Client ajouté avec succès !");
        fetchCustomers();
      } else {
        toast.error("Erreur lors de l'ajout du client : " + result.error);
      }
    } catch (error) {
      toast.error("Erreur lors de l'envoi des données.");
    } finally {
      setSubmitting(false);
      handleClose();
    }
  };

  const handleCheckErrors = (errors, touched) => {
    if (Object.keys(errors).length > 0) {
      Object.entries(errors).forEach(([field, message]) => {
        if (touched[field]) {
          toast.error(message);
        }
      });
    }
  };

  return (
    <ThemeModal open={open} onClose={handleClose}>
      <Box className="modal-content">
        <Box sx={{ background: colors.grey[400] }} className="modal-header">
          <Typography variant="h3" component="h2">
            Ajouter un client
          </Typography>
        </Box>

        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={checkoutSchema}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
          }) => (
            <form onSubmit={handleSubmit}>
              <Box className="modal-scroll">
                <Typography id="modal-title">Ajouter un client</Typography>
                <Box
                  display="grid"
                  gap="30px"
                  gridTemplateColumns="repeat(auto-fit, minmax(300px, 1fr))"
                >
                  <Select
                    sx={textFieldStyleModal}
                    value={values.customerType}
                    name="customerType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    <MenuItem value="professionnel">Professionnel</MenuItem>
                    <MenuItem value="particulier">Particulier</MenuItem>
                  </Select>

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Nom de l'entreprise"
                    name="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    error={touched.companyName && !!errors.companyName}
                    helperText={touched.companyName && errors.companyName}
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Email de contact"
                    name="contactEmail"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactEmail}
                    error={touched.contactEmail && !!errors.contactEmail}
                    helperText={touched.contactEmail && errors.contactEmail}
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Prénom"
                    name="contactFirstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactFirstName}
                    error={
                      touched.contactFirstName && !!errors.contactFirstName
                    }
                    helperText={
                      touched.contactFirstName && errors.contactFirstName
                    }
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Nom de famille"
                    name="contactLastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactLastName}
                    error={touched.contactLastName && !!errors.contactLastName}
                    helperText={
                      touched.contactLastName && errors.contactLastName
                    }
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Titre"
                    name="contactTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactTitle}
                    error={touched.contactTitle && !!errors.contactTitle}
                    helperText={touched.contactTitle && errors.contactTitle}
                  />
                  <Select
                    sx={textFieldStyleModal}
                    value={values.country || getDefaultCountry()}
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                  >
                    {getCountries().map((country) => (
                      <MenuItem key={country.value} value={country.value}>
                        {country.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Numéro SIRET"
                    name="numberSiret"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numberSiret}
                    error={touched.numberSiret && !!errors.numberSiret}
                    helperText={touched.numberSiret && errors.numberSiret}
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Numéro TVA"
                    name="numberTva"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numberTva}
                    error={touched.numberTva && !!errors.numberTva}
                    helperText={touched.numberTva && errors.numberTva}
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Téléphone"
                    name="officeNumber"
                    onChange={(e) => {
                      const formatted = formatPhoneNumber(e.target.value);
                      handleChange({
                        target: { name: "officeNumber", value: formatted },
                      });
                    }}
                    onBlur={(e) => {
                      const cleaned = cleanPhoneNumber(e.target.value);
                      handleChange({
                        target: { name: "officeNumber", value: cleaned },
                      });
                      handleBlur(e);
                    }}
                    value={values.officeNumber}
                    error={touched.officeNumber && !!errors.officeNumber}
                    helperText={touched.officeNumber && errors.officeNumber}
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Adresse"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    error={touched.address && !!errors.address}
                    helperText={touched.address && errors.address}
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Code postal"
                    name="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    error={touched.postalCode && !!errors.postalCode}
                    helperText={touched.postalCode && errors.postalCode}
                  />

                  <TextField
                    sx={textFieldStyleModal}
                    fullWidth
                    variant="filled"
                    label="Ville"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && !!errors.city}
                    helperText={touched.city && errors.city}
                  />
                </Box>
              </Box>

              <Box
                sx={{ background: colors.grey[400] }}
                className="modal-footer"
              >
                <Stack spacing={2} direction="row">
                  <Button variant="contained" onClick={handleClose}>
                    Annuler
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    onClick={() => handleCheckErrors(errors, touched)}
                  >
                    Enregistrer
                  </Button>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </ThemeModal>
  );
}
