import { useEffect, useState } from "react";
import { Box, Button, Grid, MenuItem, Select } from "@mui/material";
import * as Yup from "yup";
import { Formik } from "formik";
import TextField from "@mui/material/TextField";
import { textFieldStyleClassic } from "../../../../components/style/componentStyle";
import { useTheme } from "@emotion/react";
import { tokens } from "../../../../theme";
import { toast } from "react-toastify";

const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/; // Exemple de regex pour les numéros de téléphone
const postalCodeRegExp = /^\d{5}$/; // Exemple de regex pour les codes postaux (ajuster selon le pays)
const siretRegExp = /^\d{14}$/; // Exemple de regex pour les numéros SIRET français

const checkoutSchema = Yup.object({
  companyName: Yup.string("Nom de l'entreprise requis"),
  contactEmail: Yup.string().email("Email invalide"),
  contactFirstName: Yup.string(),
  contactLastName: Yup.string(),
  contactTitle: Yup.string(),
  country: Yup.string("Pays requis"),
  customerType: Yup.string("Type de client requis"),
  numberSiret: Yup.string().matches(siretRegExp, "Numéro SIRET invalide"),
  numberTva: Yup.string(),
  officeNumber: Yup.string().matches(phoneRegExp, "Téléphone invalide"),
  paymentDaysCondition: Yup.number("Conditions de paiement requises").integer(
    "La valeur doit être un nombre entier"
  ),
  postalCode: Yup.string().matches(postalCodeRegExp, "Code postal invalide"),
  city: Yup.string(),
  address: Yup.string().test(
    "is-empty-or-valid",
    "L'adresse doit être soit vide, soit respecter certaines conditions",
    function (value) {
      if (!value || value.trim() === "") {
        return true;
      }
      return true;
    }
  ),
}).test(function (values) {
  const fieldsToCheck = [
    "companyName",
    "contactEmail",
    "contactFirstName",
    "contactLastName",
    "contactTitle",
    "country",
    "customerType",
    "numberSiret",
    "numberTva",
    "officeNumber",
    "paymentDaysCondition",
    "postalCode",
    "city",
    "address",
  ];

  // Au moins un champ doit être rempli ou respecter les conditions
  return fieldsToCheck.some(
    (field) => !!values[field] && values[field].trim() !== ""
  );
});

const FormByCustomer = ({ store, id }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [customersData, setCustomersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchCustomers = async () => {
    try {
      const result = await store.customer.getCustomersById(id);
      if (result.success) {
        setCustomersData(result.data);
      } else {
        console.error(result.error);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des données :", error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, []);

  const handleSubmit = async (values, { setSubmitting }) => {
    try {
      const result = await store.customer.putCustomer(id, values);

      if (result.success) {
        toast.success("Le client à bien été mis à jour.");
      } else {
        toast.error("Des erreurs sont présentes dans le formulaire.");
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi des données :", error);
    } finally {
      setSubmitting(false);
    }
  };

  const initialValues = {
    customerType: customersData.customerType,
    companyName: customersData.companyName,
    numberSiret: customersData.numberSiret,
    numberTva: customersData.numberTva,
    contactTitle: customersData.contactTitle,
    contactLastName: customersData.contactLastName,
    contactFirstName: customersData.contactFirstName,
    contactEmail: customersData.contactEmail,
    phone_number: customersData.phone_number,
    officeNumber: customersData.officeNumber,
    contact_email: customersData.contact_email,
    address: customersData.address,
    postalCode: customersData.postalCode,
    city: customersData.city,
    country: customersData.country,
    paymentDaysCondition: customersData.paymentDaysCondition,
  };

  console.log(isLoading);
  return (
    <>
      {isLoading ? (
        <div>Chargement...</div>
      ) : (
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({
            values,
            errors,
            touched,
            handleBlur,
            handleChange,
            handleSubmit,
            dirty,
          }) => (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={6}>
                  <Select
                    fullWidth
                    id="demo-simple-select"
                    value={values.customerType}
                    name="customerType"
                    onChange={handleChange}
                  >
                    <MenuItem value={"CUSTOMER_TYPE_PRO"}>
                      professionnel
                    </MenuItem>
                    <MenuItem value={"CUSTOMER_TYPE_PARTICULIER"}>
                      particulier
                    </MenuItem>
                  </Select>
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Nom de l'entreprise"
                    name="companyName"
                    id="companyName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.companyName}
                    error={touched.companyName && !!errors.companyName}
                    helpertext={touched.companyName && errors.companyName}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Email de contact"
                    name="contactEmail"
                    type="email"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactEmail}
                    error={touched.contactEmail && !!errors.contactEmail}
                    helpertext={touched.contactEmail && errors.contactEmail}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Prénom du contact"
                    name="contactFirstName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactFirstName}
                    error={
                      touched.contactFirstName && !!errors.contactFirstName
                    }
                    helpertext={
                      touched.contactFirstName && errors.contactFirstName
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Nom de famille du contact"
                    name="contactLastName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactLastName}
                    error={touched.contactLastName && !!errors.contactLastName}
                    helpertext={
                      touched.contactLastName && errors.contactLastName
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Titre du contact"
                    name="contactTitle"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.contactTitle}
                    error={touched.contactTitle && !!errors.contactTitle}
                    helpertext={touched.contactTitle && errors.contactTitle}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Pays"
                    name="country"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.country}
                    error={touched.country && !!errors.country}
                    helpertext={touched.country && errors.country}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Numéro SIRET"
                    name="numberSiret"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numberSiret}
                    error={touched.numberSiret && !!errors.numberSiret}
                    helpertext={touched.numberSiret && errors.numberSiret}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Numéro TVA"
                    name="numberTva"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.numberTva}
                    error={touched.numberTva && !!errors.numberTva}
                    helpertext={touched.numberTva && errors.numberTva}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Téléphone"
                    name="officeNumber"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.officeNumber}
                    error={touched.officeNumber && !!errors.officeNumber}
                    helpertext={touched.officeNumber && errors.officeNumber}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Code postal"
                    name="postalCode"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.postalCode}
                    error={touched.postalCode && !!errors.postalCode}
                    helpertext={touched.postalCode && errors.postalCode}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Ville"
                    name="city"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.city}
                    error={touched.city && !!errors.city}
                    helpertext={touched.city && errors.city}
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Conditions de paiement"
                    type="number"
                    name="paymentDaysCondition"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.paymentDaysCondition}
                    error={
                      touched.paymentDaysCondition &&
                      !!errors.paymentDaysCondition
                    }
                    helpertext={
                      touched.paymentDaysCondition &&
                      errors.paymentDaysCondition
                    }
                  />
                </Grid>
                <Grid item xs={12} lg={6}>
                  <TextField
                    sx={textFieldStyleClassic}
                    fullWidth
                    variant="filled"
                    label="Adresse"
                    name="address"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.address}
                    error={touched.address && !!errors.address}
                    helpertext={touched.address && errors.address}
                  />
                </Grid>
              </Grid>
              {dirty && (
                <Box>
                  <Button
                    sx={{ mt: "20px" }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    type="submit"
                  >
                    Enregistrer
                  </Button>
                </Box>
              )}
            </form>
          )}
        </Formik>
      )}
    </>
  );
};

export default FormByCustomer;
