import { makeAutoObservable } from "mobx";
import { ApiURL } from "../../../constants/URL";
import { ApiService } from "../../ApiService.tsx";

interface User {
  id: string;
  name: string;
  email: string;
  // Ajoutez d'autres champs utilisateur ici si nécessaire
}

interface UserResponse {
  success: boolean;
  data?: User | User[];
  error?: string;
  message?: string;
}

interface GlobalStore {
  authService: { token: string };
  isAuthenticated: boolean;
}

class UserService {
  globalStore: GlobalStore;
  users: User[] = [];

  

  constructor(globalStore: GlobalStore) {
    this.globalStore = globalStore;
    makeAutoObservable(this);
  }

  async handleResponse(response: Response): Promise<UserResponse> {
    try {
      const data = await response.json();
      if (data["hydra:member"] !== undefined) {
        this.users = data["hydra:member"];
        this.globalStore.isAuthenticated = true;
        return { success: true, data: this.users };
      } else {
        return { success: true, data };
      }
    } catch (error: any) {
      console.error("Error handling response:", error);
      return {
        success: false,
        error: "Error handling response",
        message: error.message || "An unexpected error occurred",
      };
    }
  }

  /**
   * Récupère tous les utilisateurs.
   * @returns Un objet UserResponse contenant le statut de succès et les données utilisateur.
   */
  async getUsers(): Promise<UserResponse> {
    if (!this.globalStore.authService.token) {
      return { success: false, error: "Authentication token missing" };
    }

    try {
      const response = await ApiService.request(ApiURL.Users, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  /**
   * Récupère un utilisateur par son ID.
   * @param id - ID de l'utilisateur.
   * @returns Un objet UserResponse contenant le statut de succès et les données utilisateur.
   */
  async getUserById(id: string): Promise<UserResponse> {
    if (!this.globalStore.authService.token) {
      return { success: false, error: "Authentication token missing" };
    }

    try {
      const response = await ApiService.request(`${ApiURL.Users}/${id}`, {
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      this.globalStore.isAuthenticated = false;
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  /**
   * Ajoute un nouvel utilisateur.
   * @param userData - Données de l'utilisateur.
   * @returns Un objet UserResponse contenant le statut de succès et les données utilisateur.
   */
  async postUser(userData: Partial<User>): Promise<UserResponse> {
    if (!this.globalStore.authService.token) {
      return { success: false, error: "Authentication token missing" };
    }

    console.log("Données utilisateur à envoyer:", userData);

    try {
      const response = await ApiService.request(ApiURL.Users, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${this.globalStore.authService.token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      });
      return await this.handleResponse(response);
    } catch (error: any) {
      console.log("Error posting user", error);
      return {
        success: false,
        error: `Network or parsing error: ${error.message}`,
      };
    }
  }

  /**
   * Met à jour un utilisateur existant.
   * @param id - ID de l'utilisateur.
   * @param userData - Données mises à jour de l'utilisateur.
   * @returns Un objet UserResponse contenant le statut de succès et les données utilisateur.
   */
  async putUser(id: string, userData: Partial<User>): Promise<UserResponse> {
    if (!this.globalStore.authService.token) {
        return { success: false, error: "Authentication token missing" };
    }

    console.log("Données utilisateur à envoyer pour la mise à jour:", userData); // Log des données utilisateur

    try {
        const response = await ApiService.request(`${ApiURL.Users}/${id}`, {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${this.globalStore.authService.token}`,
                "Content-Type": "application/json",
            },
            body: JSON.stringify(userData),
        });
        return await this.handleResponse(response);
    } catch (error: any) {
        return {
            success: false,
            error: `Network or parsing error: ${error.message}`,
        };
    }
}
  
/**
   * Regarde qui est connecté.
   */
  async getCurrentUser(): Promise<UserResponse> {
    const token = this.globalStore.authService.token;
    if (!token) {
      return { success: false, error: "Authentication token missing" };
    }
  
    try {
      const response = await fetch(`${ApiURL.CurrentUser}`, {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      });
  
      const data = await response.json();
  
      if (response.ok) {
        this.globalStore.isAuthenticated = true;
        return { success: true, data };
      } else {
        this.globalStore.isAuthenticated = false;
        return {
          success: false,
          error: data.error || "Failed to fetch current user",
        };
      }
    } catch (error: any) {
      console.error("Error fetching current user:", error);
      return {
        success: false,
        error: `Network error: ${error.message}`,
      };
    }
  }
  
}

export default UserService;
