import React, { useRef } from "react";
import { Typography } from "@mui/material";
import bg from "../../../img/pdfGenerator/greenbg.png";
import logo from "../../../img/pdfGenerator/logo.png";
import product from "../../../img/pdfGenerator/produit.png";
import "./PdfGenerator.css";
import {
  MdLocationPin,
  MdOutlineRemoveRedEye,
  MdArrowUpward,
} from "react-icons/md";
import Input from "./Input";
import MapComponent from "./MapComponent";

export function CreateViewProduct({ productData }) {
  const contentRef = useRef(null);

  if (!productData) {
    return <Typography>Aucune donnée de produit disponible</Typography>;
  }

  return (
    <div className="p-4">
      <div
        ref={contentRef}
        className="pdf_container_product"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="pdf_header">
          <div className="header_item logobox">
            <img src={logo} alt="logo ceven" />
          </div>
          <div className="doc_info">
            <div className="header_item doc_name">
              <span>
                Fiche <br /> signalétique
              </span>
            </div>
            <div className="header_item contact">
              <div className="contact_title">
                <span>Contact</span>
              </div>
              <div className="responsable_name">
                <Input
                  type="text"
                  name="contact_name"
                  placeholder="Nom Prénom"
                  value={`${productData.userDetails?.firstName || ""} ${
                    productData.userDetails?.lastName || ""
                  }`}
                  readOnly
                />
              </div>
              <div className="responsable_phone">
                <Input
                  type="text"
                  name="contact_phone"
                  placeholder="Téléphone"
                  value={productData.userDetails?.phone || ""}
                  readOnly
                />
              </div>
              <div className="responsable_email">
                <Input
                  type="text"
                  name="contact_email"
                  placeholder="Email"
                  value={productData.userDetails?.email || ""}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        <div className="product_image">
          <img src={product} alt="Produit" />
        </div>

        <div className="product-details">
          <div className="product-details-item">
            <div className="product-details-item-title">ADRESSE</div>
            <div className="product-details-item-content">
              <div className="item-content-icon">
                <MdLocationPin />
              </div>
              <div className="adresse-content-value">
                <Input
                  type="text"
                  name="city"
                  placeholder="Ville"
                  value={productData.productLocalisation?.city || ""}
                  readOnly
                />
                <span className="adresse-departement">
                  <Input
                    type="text"
                    name="route"
                    placeholder="Route"
                    value={productData.route || ""}
                    readOnly
                  />
                </span>
                <span>
                  <Input
                    type="text"
                    name="address"
                    placeholder="Adresse"
                    value={productData.productLocalisation?.address || ""}
                    readOnly
                  />
                </span>
              </div>
            </div>
          </div>

          <div className="product-details-item">
            <div className="product-details-item-title">
              <span>
                Visible <span style={{ fontSize: "10px" }}>en venant de </span>
              </span>
            </div>
            <div className="product-details-item-content">
              <div className="item-content-icon">
                <MdOutlineRemoveRedEye />
              </div>
              <div className="adresse-content-value">
                <Input
                  type="text"
                  name="visibilityFrom"
                  placeholder="Sens d'arrivée"
                  value={productData.visibilityFrom || ""}
                  readOnly
                />
                <span style={{ color: "#14a438" }}>VERS </span>
                <Input
                  type="text"
                  name="visibilityTo"
                  placeholder="Sens destination"
                  value={productData.visibilityTo || ""}
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="product-details-item">
            <div className="product-details-item-title">Direction</div>
            <div className="product-details-item-content">
              <div className="item-content-icon">
                <MdArrowUpward />
              </div>
              <div className="adresse-content-value">
                <Input
                  type="text"
                  name="direction"
                  placeholder="Direction"
                  value={productData.productLocalisation?.direction || ""}
                  readOnly
                />
              </div>
            </div>
          </div>
        </div>

        <MapComponent
          latitude={+productData.productLocalisation?.latitude || 0}
          longitude={+productData.productLocalisation?.longitude || 0}
        >
          <Input
            type='text'
            name="latitude"
            placeholder="Saisir latitude"
            value={productData.productLocalisation?.latitude || ''}
          />
          <Input
            type='text'
            name="longitude"
            placeholder="Saisir longitude"
            value={productData.productLocalisation?.longitude || ''}
          />
        </MapComponent>

        <div className="social-info">
          Siège Social : Pierrelay (val d'Oise) S.A.R.L au capital de 18000€.
          RCS : 35368859 Code APE : 7311Z
        </div>
      </div>
    </div>
  );
}
