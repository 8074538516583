import React from 'react';
import { Navigate } from 'react-router-dom';
import { useGlobalStore } from '../globalStore.tsx';

const PrivateRoute = ({ children }) => {
  
  const globalStore = useGlobalStore();
  const isAuth = globalStore.authService.isAuth();

  return isAuth ? children : <Navigate to="/login" />;
};

export default PrivateRoute;