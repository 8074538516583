import React, { useState } from "react";
import { LinearProgress } from "@mui/material";
import { Button } from "@mui/material";

const FileUpload = () => {
  const [uploadProgress, setUploadProgress] = useState(0);

  const handleFileUpload = async (event) => {
    event.preventDefault(); // Empêche le rechargement de la page

    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);

    console.log("Uploading file:", file); 
  };

  return (
    <div>
      <form onSubmit={handleFileUpload}>
        <input
          accept="image/*"
          className=""
          id="contained-button-file"
          type="file"
          onChange={handleFileUpload}
        />
        {uploadProgress > 0 && (
          <LinearProgress variant="determinate" value={uploadProgress} />
        )}
        <Button type="submit">Submit</Button>
      </form>
    </div>
  );
};

export default FileUpload;
