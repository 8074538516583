export function ReturnStringFromTable(tableau) {
  // Si ce n'est pas un tableau, on renvoie false
  if (!Array.isArray(tableau)) {
    return false;
  }

  let resultat = "";

  tableau.forEach((element) => {
    // Si l'élément est un tableau, on le traite récursivement
    if (Array.isArray(element)) {
      resultat += ReturnStringFromTable(element);
    } else {
      // Ajoute l'élément au résultat en le convertissant en chaîne de caractères
      resultat += String(element);
    }
  });

  return resultat;
}
